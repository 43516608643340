import { Type } from "@angular/core";

export enum TabHeaderProgress {
  inProgress = 'inProgress',
  done = 'done',
}

export interface TabContentContext {
  getHeaderProgress(tabId: string): TabHeaderProgress;
}

export interface TabContentComponent {
  setId(id: string);
  get id(): string;
  setContext(context: TabContentContext);
  get context(): TabContentContext;
  onTabActive(active: boolean);
}

export interface Tab<T extends TabContentComponent> {
  id: string;
  title: string;
  component: Type<T>;           // component class
  active: boolean;
  context: any;
}

export function isTabContentComponent(obj: any): obj is TabContentComponent {
  return typeof (obj as TabContentComponent)?.setId === 'function' && 
        typeof (obj as TabContentComponent)?.setContext === 'function';
}