import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'audit-log-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailAuditLogComponent extends BaseFormDialog1  implements OnInit {
  private _data = {};

  @Input() get data() {return this._data}
  set data(value) {
    Object.assign(this._data, value);
    // xoá các trường mà component AuditLogList tự động thêm vào để xử lý UI
    for (let key of ['isExpand', 'detailJson', 'isShowingDetail']) {
      delete this._data[key];
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
