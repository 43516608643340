
import { Component, Input } from '@angular/core';
import { Bar } from '@antv/g2plot';
import { ChartBase } from '../chart-base';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[chart-bar]',
  templateUrl: './index.html',
})

export class ChartBarComponent extends ChartBase {

  public data;
  private _options: any;

  @Input() set options(value) {
    this._options = value?.options;
    this.data = value?.data;
  }

  get options() {
    return this._options;
  }

  @Input() id: string;

  ngOnInit(): void {
    setTimeout(() => {
      this.chart = new Bar(this.id, {
        data: this.data,
        ...this._options,
        padding: 'auto',
      });
      this.chart.render();
    }, 500);
  }

  ngOnChanges(): void {
    if(this.data && this.chart) {
      setTimeout(() => {
        this.chart.changeData(this.data);
      }, 500);
    }
  }
}
