import { BaseFormDrawer } from "@drawers/base-form";
import { Utils } from "@services/utils";

export class ListFilterDrawer extends BaseFormDrawer {
  public doFilter: (condition, metadata) => void = () => {};
  
  get canClear(): boolean {
    return Utils.isObjectNotEmpty(this.getFormData_JSON(true));
  }

  protected getMetadata() {
    return null;
  }
  
  onBtnClear() {
    for (let key of this.formInputKeys) {
      if (this.formGroupDeclaration[key].readOnly) {
        continue;
      }
      this.formInput.get(key).setValue(null);
    }
  }
  
  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    let metadata: any = this.getMetadata(); //{client: this.selectedClient, warehouse: this.selectedWarehouse};
    this.doFilter(json, metadata);
    this.onClose();
  }

}