import { Component, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FormArray } from "@angular/forms/forms";
import { DateUtil } from "@services/date-utils";
import { TimeWindow } from "@wearewarp/types/data-model";
import { BaseFormItem } from "../form-item";

// TODO: chưa xử lý trường hợp khác ngày
@Component({
  selector: '[time-window]',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: forwardRef(() => TimeWindowComponent),
    }
  ],
})
export class TimeWindowComponent extends BaseFormItem implements ControlValueAccessor {
  public static get declaration(): FormGroupDeclaration {return {
    date: {label: 'Date', type: 'date', required: true, isChanged: DateUtil.diffYYYYMMDD},
    fromTime: {label: 'Time window', type: 'time', required: true, placeHolder: 'from time', isChanged: DateUtil.diffHHmm},
    toTime: {label: '', type: 'time', required: true, placeHolder: 'to time', isChanged: DateUtil.diffHHmm},
  }}

  protected formGroupDeclaration: FormGroupDeclaration = TimeWindowComponent.declaration;

  @Input() timezone: string;
  @Input() autoFillToTime = false;    // tự động điền toTime = fromTime
  @Input() showTimezone = false;
  private _isDisabled = false;
  @Input() get isDisabled(): boolean {return this._isDisabled};
  set isDisabled(value: boolean) {
    if (value != this._isDisabled) {
      this._isDisabled = value;
      if (this.formInput) {
        this.setEnableFormGroup(value);
      }
    }
  }

  @Output() modelChange = new EventEmitter<any>();

  private touched = false;
  private value: TimeWindow;
  private onTouched = () => {};
  private onChange = (value) => {};

  constructor() {
    super();
  }

  protected setEnableFormGroup(enabled: boolean): void {
    if (enabled && this.isDisabled) {
      super.setEnableFormGroup(false);
    } else {
      super.setEnableFormGroup(enabled);
    }
  }

  getTimezoneShort() {
    return DateUtil.timezoneStandardToUsShort(this.timezone);
  }

  getLabel(key: string): string {
    let str = super.getLabel(key);
    if (key == 'fromTime' && this.showTimezone) {
      str += ` (${this.getTimezoneShort()})`;
    }
    return str;
  }

  writeValue(value: TimeWindow): void {
    this.value = value;
  }
  
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(value: boolean): void {
    this.isDisabled = value;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private getDataFromUI(): TimeWindow {
    let date = <Date>this.getFormItemValue('date');
    let fromTime = <Date>this.getFormItemValue('fromTime');
    let toTime = <Date>this.getFormItemValue('toTime');
    return DateUtil.toTimeWindow(date, fromTime, toTime, this.timezone);
  }

  onFormDataChange(key: string, value) {
    if (key == 'fromTime' && this.autoFillToTime) {
      // sau khi điền fromTime thì tự động điền luôn toTime cùng giá trị
      let fc = (<FormArray>this.formInput.get('windows'))?.at(0)?.get('toTime');
      if (fc && !fc.value) {
        fc.setValue(value);
      }
    }
    let window = this.getDataFromUI();
    if (this.isValueChange(this.value, window)) {
      this.value = window;
      this.modelChange.emit(window);
      this.onChange(window);
    }
  }

  isValueChange(v1: TimeWindow, v2: TimeWindow): boolean {
    if (v1 == null && v2 == null) {
      return false;
    }
    if ((v1 == null && v2 != null) || (v1 != null && v2 == null)) {
      return true;
    }
    return v1.from != v2.from || v1.to != v2.to;
  }
}
