import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import { BaseForm } from "./form-base";
import { FormUtil } from "@services/form-util";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";

@Directive()
export class BaseFormTemplate<T, F extends BaseForm<T>> implements OnInit, OnDestroy {
  @Input() model: T;
  @Input() formComponent: F;

  get formInput() { return this.formComponent.formInput }
  get declaration() { return this.formComponent.declaration }

  ngOnInit(): void {
  }

  ngOnDestroy():void {
  }

  isRequired(key: string): boolean {
    return FormUtil.isRequired(key, this.declaration);
  }

  isHidden(key: string): boolean {
    return FormUtil.isHidden(key, this.declaration);
  }

  isReadOnly(key: string): boolean {
    return FormUtil.isReadOnly(key, this.declaration);
  }

  isMultiline(key: string): boolean {
    return FormUtil.isMultiline(key, this.declaration);
  }

  getLabel(key: string): string {
    return FormUtil.getLabelForKey(<string>key, this.declaration);
  }

  getPlaceHolder(key: string): FormControlPlaceHolder {
    return (FormUtil.getItemByKey(key, this.declaration)?.placeHolder ?? "");
  }
  onInputChanged(event, key) {
  }
  onInputKeyPress(event, key) {

  }
  getInputType(key) {

  }
  getItemValue(key: string): any {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return null;
    }
    return FormUtil.getFormItemData(item.formControl, item.declaration);
  }

  setItemValue(key: string, value) {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return;
    }
    FormUtil.bindData(item.formControl, value, item.declaration);
  }

  // key can be like this: 'customer.warehouses[2].address.city'
  getChildFormInfoByKey(key: string): {
    formControl: AbstractControl;
    declaration: FormControlDeclaration;
  } {
    let arr = key.split(".");
    let currentControl: AbstractControl = this.formInput;
    let currentDeclaration: FormGroupDeclaration | FormControlDeclaration = this.declaration;
    for (let i = 0; i < arr.length; i++) {
      if (!currentControl) {
        return null;
      }
      let match = arr[i].match(/\[[0-9]+\]$/);
      let subKey = arr[i];
      if (match && match[0]) {
        subKey = arr[i].substring(0, match.index);
        let index = Number(match[0].replace(/[^0-9]/g, ""));
        currentControl = (<FormArray>currentControl.get(subKey)).at(index);
      } else {
        currentControl = currentControl.get(subKey);
      }
      currentDeclaration = currentDeclaration[subKey];
      if (
        currentControl instanceof FormGroup ||
        currentControl instanceof FormArray
      ) {
        currentDeclaration = currentDeclaration.childItem;
      }
    }
    return {
      formControl: <FormControl>currentControl,
      declaration: <FormControlDeclaration>currentDeclaration,
    };
  }

  join(...args): string {
    return args.join(".");
  }

}
