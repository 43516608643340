import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
// import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
// import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
// import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { AuditLogList } from './list';
import { DetailModule } from '../base/detail.module';
import { ClientModule } from '../clients/module';
import { ApiKeyModule } from '../apikey/module';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import {DetailAuditLogComponent} from "./components/detail.component"
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    NzTableModule,
    NzDividerModule,
    // SearchBoxModule,
    // NzDropDownModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzIconModule,
    // NzCheckboxModule,
    DetailModule,
    ClientModule,
    ApiKeyModule,
    SearchBoxModule,
    NgxJsonViewerModule,
    NzTagModule,
  ],
  declarations: [
    AuditLogList,
    DetailAuditLogComponent,
  ],
  exports: [
    AuditLogList,
  ],
  providers: [
  ]
})
export class AuditLogModule {}
