<style>
::ng-deep .form-address-us .ant-form-item.bottom0 {
  margin-bottom: 0;
}
.form-address-us .top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0px;
  margin-bottom: -1px;
}
.form-address-us .top:not(.ant-input[disabled]):hover, 
.form-address-us .top:not(.ant-input[disabled]):focus, 
.form-address-us .ant-form-item-has-error .ant-input.top {
  border-bottom-width: 1px;
}
.form-address-us .top-left,
::ng-deep .form-address-us .top-left .ant-select-selector {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-width: 0px;
}
.form-address-us .top-right,
::ng-deep .form-address-us .top-right .ant-select-selector {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-width: 0px;
  border-left-width: 0;
}
.form-address-us .middle:not(.ant-form-item-has-error) {
  border-radius: 0;
}
.form-address-us .middle,
::ng-deep .form-address-us .middle .ant-select-selector {
  border-bottom-width: 0px;
}
.form-address-us .middle:not(.ant-input[disabled]):hover,
.form-address-us .middle:not(.ant-input[disabled]):focus,
.form-address-us .ant-form-item-has-error .ant-input.middle,
::ng-deep .form-address-us .middle.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-bottom-width: 1px;
}
.form-address-us .bottom-left {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-address-us .bottom-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}
.form-address-us .bottom-right:not(.ant-input[disabled]):hover,
.form-address-us .bottom-right:not(.ant-input[disabled]):focus,
.form-address-us .ant-form-item-has-error .ant-input.bottom-right {
  border-left-width: 1px;
}
::ng-deep .form-address-us .bottom-left .ant-select-selector {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
::ng-deep .form-address-us-dropdown-state .add-state-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
}
::ng-deep .form-address-us-dropdown-state .add-state {
  flex: 0 0 auto;
  padding: 8px;
  display: block;
}
::ng-deep .form-address-us-dropdown-state nz-divider {
  margin: 4px 0;
}
</style>
<form nz-form [formGroup]="formInput" class="form-address-us">
  <ng-container *ngIf="!contentView">
    <ng-container *ngIf="freeTypeInput">
      <nz-input-group [nzSuffix]="suffixIconLoad">
        <input nz-input #inputFreeType [disabled]="isAdminReadOnlyRole || !isEnabled"
          [(ngModel)]="modelInputFreeType" [ngModelOptions]="{standalone: true}"
          [nzAutocomplete]="autoComplete1"
          (input)="onInputChanged($event, 'freeTypeInput')"
          (focusout)="onInputFocusOut($event, 'freeTypeInput')" >
      </nz-input-group>
      <ng-template #suffixIconLoad>
        <i *ngIf="isLoading" nz-icon nzType="loading"></i>
      </ng-template>
      <nz-autocomplete #autoComplete1 [nzDefaultActiveFirstOption]="false">
        <nz-auto-option *ngFor="let item of listAddressAutoComplete; let i = index" 
          [nzValue]="item.streetLine" (selectionChange)="onSelectSuggestedAddress($event, inputFreeType, i)">
          {{item.desc}}
        </nz-auto-option>
      </nz-autocomplete>
    </ng-container>

    <ng-container *ngIf="!freeTypeInput">
      <nz-form-item class="bottom0">
        <nz-form-control>
          <input nz-input formControlName="street" #inputStreet
            [placeholder]="getPlaceHolder('street')" class="top" 
            [nzAutocomplete]="autoComplete"
            (input)="onInputChanged($event, 'street')">
          <nz-autocomplete #autoComplete [nzDefaultActiveFirstOption]="false">
            <nz-auto-option *ngFor="let item of listAddressAutoComplete; let i = index" 
              [nzValue]="item.streetLine" (selectionChange)="onSelectSuggestedAddress($event, inputStreet, i)">
              {{item.desc}}
            </nz-auto-option>
          </nz-autocomplete>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="bottom0" *ngIf="showInputStreet2">
        <nz-form-control>
          <input nz-input formControlName="street2" #inputStreet
            [placeholder]="getPlaceHolder('street2')" class="top" 
            (input)="onInputChanged($event, 'street2')">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="bottom0">
        <nz-form-control>
          <input nz-input formControlName="city" [placeholder]="getPlaceHolder('city')" class="middle">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="bottom0" *ngIf="showInputCountry">
        <nz-form-control>
          <nz-select nzBackdrop="true" class="middle" style="width: 100%;" 
            nzShowSearch nzAllowClear 
            [nzPlaceHolder]="getPlaceHolder('countryId')" 
            formControlName="countryId"
            (ngModelChange)="onInputChanged($event, 'countryId')">
            <nz-option *ngFor="let item of allCountries" [nzLabel]="item.name" [nzValue]="item._id"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div class="flex">
        <nz-form-item class="flex1">
          <nz-form-control>
            <nz-select nzBackdrop="true" class="bottom-left" style="width: 100%;" 
              [nzDropdownRender]="isCountryUS ? null : tplAddState"
              [nzDropdownClassName]="'form-address-us-dropdown-state'"
              nzShowSearch nzAllowClear 
              [nzPlaceHolder]="getPlaceHolder('state')" 
              formControlName="state">
              <nz-option *ngFor="let state of allStates" [nzLabel]="getStateDesc(state)" [nzValue]="state.code"></nz-option>
            </nz-select>
            <ng-template #tplAddState>
              <nz-divider></nz-divider>
              <div class="add-state-container">
                <input type="text" nz-input #inputElement />
                <a class="add-state" (click)="addState(inputElement.value)">
                  <span nz-icon nzType="plus"></span>
                  Add state
                </a>
              </div>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="flex1">
          <nz-form-control>
            <input nz-input formControlName="zipcode" [placeholder]="getPlaceHolder('zipcode')" (keypress)="onlyNumberKey($event)" class="bottom-right" (input)="onInputChanged($event, 'zipcode')">
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>

    <ng-container *ngIf="showInputMetadata">
      <div>Metadata<span class="label-mark-required"></span></div>
      <form nz-form formGroupName="metadata">
        <div class="flex">
          <nz-form-item class="flex1 bottom0">
            <nz-form-control>
              <nz-select nzBackdrop="true" class="top-left" style="width: 100%;" 
                [nzDropdownRender]="isCountryUS ? null : tplAddShortTimezone"
                [nzDropdownClassName]="'form-address-us-dropdown-state'"
                [nzPlaceHolder]="getPlaceHolder('timeZone')" 
                nzShowSearch nzAllowClear 
                formControlName="timeZone">
                <nz-option *ngFor="let item of allShortTimezones" [nzLabel]="item" [nzValue]="item"></nz-option>
              </nz-select>
              <ng-template #tplAddShortTimezone>
                <nz-divider></nz-divider>
                <div class="add-state-container">
                  <input type="text" nz-input #inputElement />
                  <a class="add-state" (click)="addShortTimezone(inputElement.value)">
                    <span nz-icon nzType="plus"></span>
                    Add time short zone
                  </a>
                </div>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="flex1 bottom0">
            <nz-form-control>
              <nz-select nzBackdrop="true" style="width: 100%;" nzShowSearch nzAllowClear [nzPlaceHolder]="getPlaceHolder('timeZoneStandard')" formControlName="timeZoneStandard" class="top-right">
                <nz-option *ngFor="let item of allTimezones" [nzLabel]="item.tzCode" [nzValue]="item.tzCode"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="flex">
          <nz-form-item class="flex1">
            <nz-form-control>
              <input nz-input formControlName="latitude" [placeholder]="getPlaceHolder('latitude')" class="bottom-left" (focusout)="onInputFocusOut($event, 'latitude')">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="flex1">
            <nz-form-control>
              <input nz-input formControlName="longitude" [placeholder]="getPlaceHolder('longitude')" class="bottom-right" (focusout)="onInputFocusOut($event, 'longitude')">
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>
    </ng-container>
  </ng-container>


  <ng-container *ngIf="contentView">
    <ng-container *ngTemplateOutlet="contentView;context:{}"></ng-container>
  </ng-container>
</form>
