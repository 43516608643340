import { AfterViewInit, Component, ComponentRef, Input, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { Tab, TabContentComponent, TabContentContext, isTabContentComponent } from "../interface";

@Component({
  selector: "ngx-tab",
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export default class TabComponent<T extends TabContentComponent> implements AfterViewInit {

  @Input() set ops(value: Tab<T>) {
    this._id = value.id;
    this._active = value.active;
    this._title = value.title;
    this._componentClass = value.component;
    this._context = value.context;
  }

  @ViewChild('view', {read: ViewContainerRef}) view: ViewContainerRef;

  private _id: string;
  private _active = false;
  private _title: string;
  private _componentClass: Type<T>;
  private _compRef: ComponentRef<T>;
  private _context: TabContentContext;

  constructor() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.initContentComponent(), 1);
  }

  private initContentComponent() {
    this._compRef = this.view.createComponent(this._componentClass);
    if (!isTabContentComponent(this.component)) {
      throw Error(`class ${this._componentClass.name} must implement TabContentComponent interface`);
    }
    this._compRef.instance.setId(this.id);
    this._compRef.instance.setContext(this.context);
  }

  get tabTitle(): string {
    return this._title;
  }

  get component(): T {
    return this._compRef?.instance;
  }

  get context() {
    return this._context;
  }

  get id(): string {
    return this._id;
  }

  get isActive(): boolean {
    return this._active;
  }

  setActive(value: boolean) {
    this._active = value;
  }

}
