
import { Component, Input } from '@angular/core';
import { Line } from '@antv/g2plot';
import { ChartBase } from '../chart-base';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[chart-line]',
  templateUrl: './index.html',
})

export class ChartLineComponent extends ChartBase {

  public data;
  private _options: any;

  @Input() set options(value) {
    this._options = value?.options;
    this.data = value?.data;
  }

  get options() {
    return this._options;
  }

  @Input() id: string;

  ngOnInit(): void {
    setTimeout(() => {
      this.chart = new Line(this.id, {
        data: this.data,
        ...this._options,
        padding: 'auto',
        height: 500,
        xAxis: {
          tickCount: 5,
        },
        yAxis: {
          label: {
            formatter: (v) => InputHelper._formatMoney(v, 2)
          },
        },
        tooltip: {
          formatter: (data) => {
            return {
              name: this._options.seriesField ? data.category : this._options.yField,
              value: InputHelper._formatMoney(data[this._options.yField], 2)
            };
          },
        }
      });
      this.chart.render();
    }, 500);
  }

  ngOnChanges(): void {
    if(this.data && this.chart) {
      setTimeout(() => {
        this.chart.changeData(this.data);
      }, 500);
    }
  }
}
