import { Component } from "@angular/core";

@Component({
  selector: 'span[mark-required], div[mark-required]',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss',
  ],
  host: {
    class: 'label-mark-required'
  }
})
export class MarkRequired {
}