<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <search-box #searchBox (doSearch)="doSearch($event)" placeHolder="Search by Event Name or ObjectId" style="margin-right: 16px; width: 300px !important;"
      [searchKeyword]="searchKeyword"></search-box>
    <nz-select nzBackdrop="true" (ngModelChange) = "onSearchEntity($event)" [(ngModel)]="selectedEntity" style="width: 200px; font-size: 14px; margin-right: 16px" nzSize="large" nzPlaceHolder="Select a entity" nzShowSearch nzAllowClear>
      <nz-option *ngFor="let item of logEntities" [nzValue]="item" [nzLabel]="item" ></nz-option>
    </nz-select>
    <nz-select nzBackdrop="true" (ngModelChange) = "onSearchUser($event)" [(ngModel)]="selectedUser" style="width: 300px; font-size: 14px;" nzSize="large" nzPlaceHolder="Select a User" nzShowSearch nzAllowClear>
      <nz-option *ngFor="let item of logUsers" [nzValue]="item._id" [nzLabel]="item.name" ></nz-option>
    </nz-select>
    
  </div>
  <div class="list-body">
    <nz-table #nzTable nzBordered="true" [nzData]="listData" nzSize="small" [nzTotal]="totalCount"
      [nzFrontPagination]="false" [nzShowPagination]="totalCount > listData.length" [nzPageSize]="limit" nzShowSizeChanger
      [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)"
      (nzPageSizeChange)="onDataListPageSizeChanged($event)">

      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th nzWidth="60px"></th>
          <th nzColumnKey="_id" >Entity</th>
          <th nzColumnKey="_id" >ObjectId</th>
          <th nzColumnKey="_id" >Event Name</th>
          <th nzColumnKey="_id" >User</th>
          <th nzColumnKey="_id" nzWidth="200px">When</th>
          <th nzColumnKey="detail">Detail</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of listData; let i = index">
        <tr [ngClass]="{'hilight': item.isShowingDetail}">
          <td [nzExpand]="item.isExpand == true" (nzExpandChange)="onExpandChange(item, $event)"></td>
          <td>
            {{item.entity}}
          </td>
          <td>
            {{item.objectId}}
          </td>
          <td>
            {{item.eventName}}
          </td>
          <td>
            <div *ngIf="item.user">{{item.user}}</div>
            <nz-tag  *ngIf="!item.user" [nzColor]="'red'">No authUser!</nz-tag>
          </td>
          <td>
            {{formatDate(item.eventTime)}}
          </td>
          <td class="actions w50">
            <div class="flex">
              <button nz-button nzType="text" (click)="onClickDetail(item)" nzShape="circle"><i nz-icon nzType="eye"
                  nzTheme="outline"></i></button>
            </div>

          </td>
        </tr>
        <tr [nzExpand]="item.isExpand == true">
          <code style="white-space: pre-wrap; font-size: 12px; font-family: 'Courier New', Courier, monospace">{{ item.detailJson }}</code>
        </tr>
        </ng-container>
      </tbody>
    </nz-table>

  </div>
</div>