import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { DialogService } from "@dialogs/dialog.service";
import { FormUtil } from "@services/form-util";
import { Log } from "@services/log";
import { Utils } from "@services/utils";

export interface ValidateOptions {
  scrollToErrorField?: boolean,
  showDialogMessage?: boolean | string,
}

@Directive()
export abstract class BaseForm<T> implements OnInit, OnDestroy, AfterViewInit {
  // model là dữ liệu API trả về, tránh nhầm với khái niệm model của form control
  private _model: T;
  @Input() set model(value) {
    this._model = value;
    this.onModelDataChanged();
  }
  get model(): T {
    return this._model;
  }
  get isCreateNew(): boolean {
    return this.model == null;
  }
  @Input() public formInput: FormGroup;
  protected formGroupDeclaration: FormGroupDeclaration = {}   // cần được khai báo chi tiết ở class kế thừa

  get self() { return this }
  get declaration() { return this.formGroupDeclaration }
  get formElement(): HTMLFormElement { return this.hostElement?.nativeElement?.getElementsByTagName('form')?.item(0); }
  
  // Bình thường thì form sẽ tự tạo formInput, tuy nhiên những thằng sub form thì sẽ lấy formInput từ parent truyền vào nên không cần tạo
  get shouldCreateFormInput() {
    return this.formInput == null;
  }

  constructor(protected hostElement?: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    if (this.shouldCreateFormInput) {
      this.createFormInput(this.model);
    }
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
  }

  protected onModelDataChanged() {
  }

  protected createFormInput(bindData?: T) {
    if (!Utils.isObjectNotEmpty(this.formGroupDeclaration)) {
      return Log.w(`Skip creating formInput due to formGroupDeclaration is empty. ${this.constructor.name}`);
    }
    if (bindData) {
      this.formInput = FormUtil.createFormGroup(this.formGroupDeclaration, this.beforeBindModel(bindData));
      this.afterBindModel(bindData);
    } else {
      this.formInput = FormUtil.createFormGroup(this.formGroupDeclaration);
    }
    this.setEnableFormGroup(true); // make sure all readOnly fields will be disabled after created.
  }

  protected setEnableFormGroup(enabled: boolean) {
    FormUtil.setEnableFormGroup(this.formInput, this.formGroupDeclaration, enabled);
  }

  public bindFormData(model: T) {
    FormUtil.bindData(this.formInput, this.beforeBindModel(model), this.formGroupDeclaration);
    this.afterBindModel(model);
    this.setEnable(false);
  }

  protected beforeBindModel(model: T): T {
    return model;
  }

  protected afterBindModel(model: T) {
  }

  protected setEnable(enabled: boolean) {
    FormUtil.setEnableFormGroup(this.formInput, this.formGroupDeclaration, enabled);
  }

  public getFormData(): T {
    let data = FormUtil.getFormGroupData(this.formInput, this.formGroupDeclaration);
    return data as T;
  }

  public isFormControlExists(key: string): boolean {
    return this.getChildFormInfoByKey(key)?.formControl != null;
  }

  // Chỉ lấy những trường thay đổi trên form, trường nào không thay đổi thì không lấy
  public getFormDataChanges(): T {
    let originalData = this.isCreateNew ? undefined : this.model;
    let data = FormUtil.getFormGroupData(this.formInput, this.formGroupDeclaration, originalData);
    return data as T;
  }

  // Phát hiện xem có trường nào trên form bị thay đổi dữ liệu không
  public isFormDataChanged(): boolean {
    let originalData = this.isCreateNew ? undefined : this.model;
    return FormUtil.isFormGroupChanged(this.formInput, originalData, this.formGroupDeclaration);
  }

  public getFormArrayLength(key: string): number {
    return this.getFormArray(key)?.length ?? 0;
  }

  public getFormArray(key: string): FormArray {
    return <FormArray>this.formInput.get(key);
  }

  public getArrayControls(key: string): Array<AbstractControl> {
    let f = this.getFormArray(key);
    if (f) return f.controls || [];
    return [];
  }

  public getArrayControlsOfArray(
    key1: string,
    index: number,
    key2: string
  ): Array<AbstractControl> {
    let f = <FormArray>this.getFormArray(key1).at(index).get(key2);
    if (f) return f.controls || [];
    return [];
  }

  getInputType(key: string): string {
    return (
      FormUtil.getItemByKey(key, this.formGroupDeclaration).inputType || "text"
    );
  }

  getItemByKey(key: string): FormControl {
    return this.getChildFormInfoByKey(key).formControl as FormControl;
  }

  public addItemToFormArray(key: string, bindData = null) {
    let fa = this.getFormArray(key);
    let declaration = this.formGroupDeclaration[key];
    let f = FormUtil.createChildItem(declaration, bindData);
    fa.push(f);
  }

  public removeItemInFormArray(key: string, index: number) {
    this.getFormArray(key)?.removeAt(index);
  }

  // key can be like this: 'customer.warehouses[2].address.city'
  protected getChildFormInfoByKey(key: string): {formControl: AbstractControl, declaration: FormControlDeclaration} | null {
    let arr = key.split(".");
    let currentControl: AbstractControl = this.formInput;
    let currentDeclaration: FormGroupDeclaration | FormControlDeclaration =
      this.formGroupDeclaration;
    for (let i = 0; i < arr.length; i++) {
      if (!currentControl) {
        return null;
      }
      let match = arr[i].match(/\[[0-9]+\]$/);
      let subKey = arr[i];
      if (match && match[0]) {
        subKey = arr[i].substring(0, match.index);
        let index = Number(match[0].replace(/[^0-9]/g, ""));
        currentControl = (<FormArray>currentControl.get(subKey)).at(index);
      } else {
        currentControl = currentControl.get(subKey);
      }
      currentDeclaration = currentDeclaration[subKey];
      if (
        currentControl instanceof FormGroup ||
        currentControl instanceof FormArray
      ) {
        currentDeclaration = currentDeclaration.childItem;
      }
    }
    return {
      formControl: <FormControl>currentControl,
      declaration: <FormControlDeclaration>currentDeclaration,
    };
  }

  public getItemValue(key: string): any {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return null;
    }
    return FormUtil.getFormItemData(item.formControl, item.declaration);
  }

  public setItemValue(key: string, value) {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return;
    }
    FormUtil.bindData(item.formControl, value, item.declaration);
  }

  public validate(options?: ValidateOptions): ValidationErrors | {error: ValidationErrors, message: string} {
    let err = FormUtil.validateFormGroup(this.formInput, {includeErrorKey: true, markFormError: true});
    if (err && options?.scrollToErrorField) {
      this.scrollToFirstInvalidControl();
    }
    if (err && options?.showDialogMessage) {
      let message = typeof options.showDialogMessage == 'string' ? options.showDialogMessage : 'Please make sure all the fields in form are valid.';
      DialogService.showDialog(message);
    }
    return err;
  }

  public scrollToFirstInvalidControl() {
    let doit = (form: HTMLFormElement) => {
      let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView();
        (firstInvalidControl as HTMLElement).focus();
        return true;
      } else {
        return false;
      }
    }
    if (this.formElement) {
      return doit(this.formElement);
    }
    let forms = document.getElementsByTagName('form');
    for (let i = 0; i < forms.length; i++) {
      let form = forms.item(i);
      if (doit(form)) {
        return;
      }
    }
  }

  onInputChanged(event, key) {
  }

  onInputKeyPress(event, key) {
  }

  isRequired(key: string): boolean {
    return FormUtil.isRequired(key, this.declaration);
  }

  isHidden(key: string): boolean {
    return FormUtil.isHidden(key, this.declaration);
  }

  isReadOnly(key: string): boolean {
    return FormUtil.isReadOnly(key, this.declaration);
  }

  isMultiline(key: string): boolean {
    return FormUtil.isMultiline(key, this.declaration);
  }

  getLabel(key: string): string {
    return FormUtil.getLabelForKey(<string>key, this.declaration);
  }

  getPlaceHolder(key: string): FormControlPlaceHolder {
    return (FormUtil.getItemByKey(key, this.declaration)?.placeHolder ?? "");
  }

}