import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  AsyncValidatorFn,
} from "@angular/forms";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { Observable } from "rxjs";

export type ErrorsOptions = Record<string, NzSafeAny>;
export type ValidationErrors = Record<string, ErrorsOptions>;

export class ExtendValidators {
  static locale = "en";
  static setLocale(locale): void {
    this.locale = locale;
  }

  static required(message: string): ValidatorFn {
    return this.extendValidator("required", [], message);
  }

  static requiredTrue(message: string): ValidatorFn {
    return this.extendValidator("requiredTrue", [], message);
  }

  static minLength(minLength: number, message: string): ValidatorFn {
    return this.extendValidator("minLength", [minLength], message);
  }

  static maxLength(maxLength: number, message: string): ValidatorFn {
    return this.extendValidator("maxLength", [maxLength], message);
  }

  static min(min: number, message: string): ValidatorFn {
    return this.extendValidator("min", [min], message);
  }
  static max(max: number, message: string): ValidatorFn {
    return this.extendValidator("max", [max], message);
  }
  static email(message: string): ValidatorFn {
    return this.extendValidator("email", [], message);
  }

  static pattern(pattern: string | RegExp, message: string): ValidatorFn {
    return this.extendValidator("pattern", [pattern], message);
  }
  static nullValidator(message: string): ValidatorFn {
    return this.extendValidator("nullValidator", [], message);
  }

  static compose(validators: ValidatorFn[], message: string): ValidatorFn | null {
    return this.extendValidator("compose", [validators], message);
  }

  static extendValidator(name: string, params: any[], message: string): ValidatorFn | null {
    let validateFn = Validators[name];
    if (params.length > 0) {
      validateFn = validateFn(...params);
    }
    return (control: AbstractControl): ValidationErrors | null => {
      //if(typeof control.value == "string") control.setValue(control.value.trim())
      if (validateFn(control) === null) {
        return null;
      }

      return {
        [name]: {
          [this.locale]: message,
        },
      };
    };
  }
  static asyncValidator(name: string, validateFn: Function): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      const message = await validateFn(control.value);
      if (!message) return null;

      return {
        [name]: {
          [this.locale]: message,
        },
      };
    };
  }

  // Dùng cho nz-range-picker để đảm bảo thứ tự ngày giờ from/to phải đúng
  // Trước khi vào đây phải validate require rồi nên mặc định là có dữ liệu
  static validateTimeWindow(input: FormControl): ValidationErrors | null {
    let arr: Date[] = input.value;
    if (!arr) {
      return {error: {en: `Start date time/End date time is missing`}};
    }
    let from = arr[0];
    let to = arr[1];
    if (!from || !to) {
      return {error: {en: `Start date time/End date time is missing`}};
    }
    if (from.getTime() > to.getTime()) {
      return {error: {en: `End date time must be greater than Start date time`}};
    }
    return null;
  }

  static validateTextNotEmpty(input: FormControl): ValidationErrors | null {
    let v = (input.value ?? '').trim();
    if (v.length == 0) {
      return {error: {en: `A non-empty text is required`}};
    }
    return null;
  }

  static validateUrl(input: FormControl): ValidationErrors | null {
    let v = (input.value ?? '').trim();
    let isUrl = /^https?:\/\//.test(v);
    if (!isUrl) {
      return {error: {en: `Please enter URL`}};
    }
    return null;
  }

}
