import { Component, Input, ViewChild } from '@angular/core';
import { BaseFormItem } from '@app/admin/base/form-item';
import { InputHelper } from '@services/input-helper';
import { NzOptionSelectionChange } from 'ng-zorro-antd/auto-complete';
import { FormArray, FormControl } from '@angular/forms';
import { MasterData } from '@services/master.data';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'form-delivery-info',
  templateUrl: './comp.html',
  styleUrls: [
    './style.scss',
    '../../../../styles/form-v2.scss',
    '../../../../styles/time-window.scss'
  ]
})
export class FormDeliveryInfo extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    locationName: {label: 'Location Name', notAcceptNull: true},
    contactName: {label: 'Contact Name', notAcceptNull: true},
    contactPhone: {label: 'Contact Phone', notAcceptNull: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
    contactEmail: {label: 'Contact Email', notAcceptNull: true},
    addr: {label: 'Address'},
    windows: {label: '', type: 'formArray', initialValue: [{}], childItem: {
      date: {label: 'Date', type: 'date', required: true, isChanged: DateUtil.diffYYYYMMDD},
      fromTime: {label: 'Time window', type: 'time', required: true, placeHolder: 'from time', isChanged: DateUtil.diffHHmm},
      toTime: {label: '', type: 'time', required: true, placeHolder: 'to time', isChanged: DateUtil.diffHHmm},
    }},
    accessCode: {label: 'Access Code', notAcceptNull: true},
    instructions: {label: 'Instruction', notAcceptNull: true, multiline: true},
    note: {label: 'Note', notAcceptNull: true, multiline: true},
  };

  listLocations = []
  locationsFiltered = [];
  allStates = MasterData.getStatesUS();

  @Input() isEnabled = true;
  @Input() defaultDateTime: Date = null;

  get isCreateNew(): boolean {
    return !this.model
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.defaultDateTime) {
      this.formGroupDeclaration.windows.childItem['date'].initialValue = DateUtil.clone(this.defaultDateTime);
      this.formGroupDeclaration.windows.childItem['fromTime'].initialValue = DateUtil.clone(this.defaultDateTime);
      this.formGroupDeclaration.windows.childItem['toTime'].initialValue = DateUtil.clone(this.defaultDateTime);
    }
    if (this.model) {
      let timezone = this.model.addr?.metadata?.timeZoneStandard;
      let windows = [];
      for (let item of this.model.windows || []) {
        windows.push(DateUtil.fromTimeWindow(item, timezone));
      }
      this.model.windows = windows;
    }

    super.ngOnInit();
    this.getListLocations();
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'contactPhone':
        return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onValueChange(value: string) {
    this.locationsFiltered = this.listLocations.filter(location => location.name.toLowerCase().startsWith(value.toString().toLowerCase()));
  }

  onLocationSelected(event: NzOptionSelectionChange, locationObject) {
    if (!event.isUserInput) {
      return;
    }
    this.setLocation(locationObject);
  }

  private getListLocations() {
    let url = `${Const.APIURI_WAREHOUSES}?limit=-1`;
    this.api.GET(url).subscribe(
      resp => {
        this.listLocations = resp.data.list_data;
        this.locationsFiltered = resp.data.list_data.slice(0);
      }, err => {
        this.showErr(err);
      }
    );
  }

  private setLocation(locationObject: any) {
    this.setItemValue(`addr`, locationObject?.pickAddr);
    this.setItemValue(`contactPhone`, locationObject?.phone);
    this.setItemValue(`contactEmail`, locationObject?.email);
    this.setItemValue(`accessCode`, locationObject?.accessCode);
    this.setItemValue(`instructions`, locationObject?.deliveryInstructions);
    this.setItemValue(`note`, locationObject?.note);
    if (!locationObject) {
      this.setItemValue(`locationName`, '');
    }
  }

  getControlTimeWindows() {
    return this.getFormArrayTimeWindows()?.controls ?? [];
  }

  private getFormArrayTimeWindows(): FormArray {
    return <FormArray>this.formInput.get('windows');
  }

  onSelectTimeChange(childKey, value) {
    switch (childKey) {
      case 'fromTime':
        let fc = (<FormArray>this.formInput.get('windows'))?.at(0)?.get('toTime');
        if (fc && !fc.value) {
          fc.setValue(value);
        }
        break;
    }
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(isCreateNew);
    let timezone = data['addr']?.metadata?.timeZoneStandard;
    let windows = [];
    let fa = <FormArray>this.formInput.get('windows');
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      let date = <Date>fg.get('date').value;
      let fromTime = <Date>fg.get('fromTime').value;
      let toTime = <Date>fg.get('toTime').value;
      let fromToObj = DateUtil.toTimeWindow(date, fromTime, toTime, timezone);
      if (fromToObj) {
        windows.push(fromToObj);
      }
    }
    data.windows = windows;
    return data;
  }

}