<form nz-form [formGroup]="formInput" class="form-input-cost">
  <div class="flex bottom20 thead">
    <div class="flex1"></div>
    <div class="col-dropOffIndex th"></div>
    <div class="col-rate th">Rate</div>
    <div class="col-qty th">Quantity</div>
    <div class="col-total th">Total</div>
  </div>

  <form nz-form *ngFor="let key of ['transitCost']" [formGroupName]="key" class="transit-cost">
    <div class="flex">
      <div *ngFor="let col of rows[key].columns; let i = index" [ngClass]="{'flex1': i == 0}" class="col-{{col.key}}">
        <ng-container *ngIf="i == 0">
          {{getLabel(key)}}
          <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </ng-container>
        <nz-form-item *ngIf="i > 0" class="bottom10">
          <nz-form-control>
            <ng-container *ngIf="col.key == 'qty'">
              <input nz-input [formControlName]="col.key" 
                [ngClass]="{'readonly': isReadOnly(fullKey(key, col.key))}"
                [type]="getInputType(fullKey(key, col.key))" 
                [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                (input)="onInputChanged($event, fullKey(key, col.key))" 
                (keypress)="onInputKeyPress($event, fullKey(key, col.key))"
                (focusout)="onInputFocusOut($event, fullKey(key, col.key))" >
            </ng-container>
            <input *ngIf="col.key != 'qty'" nz-input [formControlName]="col.key"
              [ngClass]="{'readonly': isReadOnly(fullKey(key, col.key))}"
              [type]="getInputType(fullKey(key, col.key))" 
              [placeholder]="getPlaceHolder(fullKey(key, col.key))"
              (input)="onInputChanged($event, fullKey(key, col.key))" 
              (keypress)="onInputKeyPress($event, fullKey(key, col.key))"
              (focusout)="onInputFocusOut($event, fullKey(key, col.key))" >
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <ng-container *ngIf="showVolumeDiscount">
    <form nz-form *ngFor="let key of ['volumeDiscount']" [formGroupName]="key">
      <div class="flex">
        <div *ngFor="let col of rows[key].columns; let i = index" [ngClass]="{'flex1': i == 0}" class="col-{{col.key}}">
          <ng-container *ngIf="i == 0">
            {{getLabel(key)}}<br>
            <nz-radio-group formControlName="type" (ngModelChange)="onVolumeDiscountTypeChange($event)">
              <label nz-radio nzValue="flatRate">{{getLabel(fullKey(key, 'flatRate'))}}</label>
              <label nz-radio nzValue="percentage">{{getLabel(fullKey(key, 'percentage'))}}</label>
            </nz-radio-group>
          </ng-container>
          <nz-form-item *ngIf="i > 0">
            <nz-form-control>
              <ng-container [ngSwitch]="col.key">
                <ng-container *ngSwitchCase="'rate'">
                  <input *ngIf="volumeDiscountType == 'flatRate'" nz-input formControlName="flatRate"
                    [ngClass]="{'readonly': isReadOnly(fullKey(key, 'flatRate'))}"
                    [type]="getInputType(fullKey(key, 'flatRate'))" 
                    [placeholder]="getPlaceHolder(fullKey(key, 'flatRate'))"
                    (input)="onInputChanged($event, fullKey(key, 'flatRate'))" 
                    (keypress)="onInputKeyPress($event, fullKey(key, 'flatRate'))"
                    (focusout)="onInputFocusOut($event, fullKey(key, 'flatRate'))" >
                  <input *ngIf="volumeDiscountType == 'percentage'" nz-input formControlName="percentage"
                    [ngClass]="{'readonly': isReadOnly(fullKey(key, 'percentage'))}"
                    [type]="getInputType(fullKey(key, 'percentage'))" 
                    [placeholder]="getPlaceHolder(fullKey(key, 'percentage'))"
                    (input)="onInputChanged($event, fullKey(key, 'percentage'))" 
                    (keypress)="onInputKeyPress($event, fullKey(key, 'percentage'))"
                    (focusout)="onInputFocusOut($event, fullKey(key, 'percentage'))">
                </ng-container>
                <ng-container *ngSwitchCase="'qty'">
                  <div style="text-align: right; margin-right: 8px;">-</div>
                </ng-container>
                <input *ngSwitchDefault nz-input [formControlName]="col.key" [ngClass]="{'readonly': isReadOnly(fullKey(key, col.key))}">
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </ng-container>

  <div class="separator h top20 bottom20"></div>

  <ng-container *ngIf="showSubTotal">
    <div class="flex bottom20 sub-total-row">
      <div class="flex1">Sub Total</div>
      <div class="col-rate"></div>
      <div class="col-qty"></div>
      <div>
        <input nz-input formControlName="subTotal" [ngClass]="{'readonly': isReadOnly('subTotal')}">
      </div>
    </div>
    <div class="separator h top20 bottom20"></div>
  </ng-container>

  <form nz-form *ngFor="let key of ['fuelCost']" [formGroupName]="key">
    <div class="flex">
      <div *ngFor="let col of rows[key].columns; let i = index" [ngClass]="{'flex1': i == 0}" class="col-{{col.key}}">
        <ng-container *ngIf="i == 0">
          {{getLabel(key)}}<br>
          <nz-radio-group formControlName="type" (ngModelChange)="onFuelCostTypeChange($event)">
            <label nz-radio nzValue="rpm">{{getLabel(fullKey(key, 'rpm'))}}</label>
            <label nz-radio nzValue="percentage">{{getLabel(fullKey(key, 'percentage'))}}</label>
          </nz-radio-group>
        </ng-container>
        <nz-form-item *ngIf="i > 0">
          <nz-form-control>
            <ng-container [ngSwitch]="col.key">
              <ng-container *ngSwitchCase="'rate'">
                <input *ngIf="fuelCostType == 'rpm'" nz-input formControlName="rpm"
                  [ngClass]="{'readonly': isReadOnly(fullKey(key, 'rpm'))}"
                  [type]="getInputType(fullKey(key, 'rpm'))" 
                  [placeholder]="getPlaceHolder(fullKey(key, 'rpm'))"
                  (input)="onInputChanged($event, fullKey(key, 'rpm'))" 
                  (keypress)="onInputKeyPress($event, fullKey(key, 'rpm'))"
                  (focusout)="onInputFocusOut($event, fullKey(key, 'rpm'))" >
                <input *ngIf="fuelCostType == 'percentage'" nz-input formControlName="percentage"
                  [ngClass]="{'readonly': isReadOnly(fullKey(key, 'percentage'))}"
                  [type]="getInputType(fullKey(key, 'percentage'))" 
                  [placeholder]="getPlaceHolder(fullKey(key, 'percentage'))"
                  (input)="onInputChanged($event, fullKey(key, 'percentage'))" 
                  (keypress)="onInputKeyPress($event, fullKey(key, 'percentage'))"
                  (focusout)="onInputFocusOut($event, fullKey(key, 'percentage'))">
              </ng-container>
              <ng-container *ngSwitchCase="'qty'">
                <div *ngIf="fuelCostType != 'rpm'" style="text-align: right; margin-right: 8px;">-</div>
                <input *ngIf="fuelCostType == 'rpm'" nz-input formControlName="qty"
                  [ngClass]="{'readonly': isReadOnly(fullKey(key, 'qty'))}"
                  [type]="getInputType(fullKey(key, 'qty'))" 
                  [placeholder]="getPlaceHolder(fullKey(key, 'qty'))"
                  (input)="onInputChanged($event, fullKey(key, 'qty'))" 
                  (keypress)="onInputKeyPress($event, fullKey(key, 'qty'))"
                  (focusout)="onInputFocusOut($event, fullKey(key, 'qty'))">
              </ng-container>
              <input *ngSwitchDefault nz-input [formControlName]="col.key" [ngClass]="{'readonly': isReadOnly(fullKey(key, col.key))}">
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <div class="separator h top20 bottom20"></div>

  <div class="service-option-thead">
    <div class="service-option-th0">Service Options</div>
    <button class="service-option-btn-add" nz-button nzType="dashed" (click)="onBtnAddServiceOption()" [disabled]="!isEnabled">
      <i nz-icon nzType="plus"></i>Add line item
    </button>
  </div>

  <ng-container *ngFor="let key of ['serviceOptions']" formArrayName="serviceOptions">
    <form *ngFor="let item of getArrayControls(key); let i = index" nz-form [formGroupName]="i" class="service-option">
      <div class="flex">
        <div *ngFor="let col of rows[key].columns; let colIndex = index" [ngClass]="{'flex1': colIndex == 0}" class="col-{{col.key}}">
          <nz-form-item class="bottom10">
            <nz-form-control>
              <div [ngClass]="{'center-vertical': colIndex == 0}">
                <ng-container *ngIf="colIndex == 0">
                  <div class="service-option-item-indent">
                    <button nz-button class="service-option-btn-remove" [disabled]="!isEnabled"
                      nzShape="circle" nzSize="small" nzDanger 
                      (click)="onBtnRemoveServiceOption(i)">
                      <i nz-icon nzType="minus" nzTheme="outline"></i>
                    </button>
                  </div>
                  <nz-select nzBackdrop="true" style="max-width: 350px; min-width: 260px;" 
                    [formControlName]="col.key" nzShowSearch
                    (ngModelChange)="onServiceOptionItemChange(colIndex, $event)">
                    <nz-option-group *ngFor="let group of serviceOptions" [nzLabel]="group.label">
                      <nz-option *ngFor="let item of group.items"
                        [nzValue]="item._id" [nzLabel]="item.name"
                        [nzDisabled]="isDisableServiceOptionItem(item, i)">
                      </nz-option>
                    </nz-option-group>
                  </nz-select>
                </ng-container>
                <ng-container *ngIf="colIndex == 1">
                  <div class="{{col.key}}">{{getLabelDropInfoIndex(i)}}</div>
                  <input nz-input
                  [formControlName]="col.key" style="display: none;"
                  [ngClass]="{'readonly': isReadOnly(fullKey(key, col.key))}"
                  [type]="getInputType(fullKey(key, col.key))">
                </ng-container>
                <ng-container *ngIf="colIndex > 1">
                  <ng-container *ngIf="col.key == 'total'">
                    <div><span *ngIf="isSeviceOptionTypeNegative(i)">-</span>{{getServiceOptionMoneyValue(i)}}</div>
                  </ng-container>
                  <ng-container *ngIf="col.key != 'total'">
                    <input nz-input 
                    [formControlName]="col.key" class="{{col.key}}"
                    [ngClass]="{'readonly': isReadOnly(fullKey(key, col.key))}"
                    [type]="getInputType(fullKey(key, col.key))" 
                    [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                    (input)="onInputChanged($event, fullKey(key, col.key))" 
                    (keypress)="onInputKeyPress($event, fullKey(key, col.key))"
                    (focusout)="onInputFocusOut($event, key + '[' + i + ']' + '.' + col.key)">
                  </ng-container>
                </ng-container>
              </div>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="isNegativeMargin; then negativeMarginTemp"></ng-container>
  <ng-template #negativeMarginTemp>
    <div class="tdm-row cell-spacing" >
      <div *ngFor="let key of ['negativeMarginReason']" class="tdm-col tdm-col-2" >
        <div class="form-label-v2 negative-margin-reason">{{getLabel(key)}}<span class="label-mark-required"></span></div>
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" style="width: 100%;" [nzLoading]="false"
              [formControlName]="key"
              nzShowSearch nzAllowClear nzPlaceHolder="Please select reason" nzDropdownClassName="choose-negative-reason">
              <nz-option  nzCustomContent *ngFor="let reason of reasons" [nzLabel]="reason" [nzValue]="reason">
                <div>{{reason}}</div>
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div class="tdm-row cell-spacing">
      <div *ngFor="let key of ['manager']" class="tdm-col tdm-col-2" >
        <div class="form-label-v2">{{getLabel(key)}}<span class="label-mark-required"></span></div>
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" style="width: 100%;" [nzLoading]="isManagerLoading" nzOptionHeightPx="55"
              [formControlName]="key"
              nzShowSearch nzAllowClear nzPlaceHolder="Please select manager" nzDropdownClassName="choose-manager">
              <nz-option  nzCustomContent *ngFor="let manager of managers" [nzLabel]="getFullName(manager)" [nzValue]="manager._id">
                <div>{{getFullName(manager)}}</div>
                <div class="sub-info">Email: {{manager.email}}</div>
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-template>

  <div class="separator h top10 bottom20"></div>
  <div class="flex grand-total-row no-padding" *ngIf="totalShipmentCost">
    <div class="flex1">Revenue</div>
    <div class="col-rate"></div>
    <div class="col-qty"></div>
    <div>
      {{getRevenue()}}
    </div>
  </div>

  <div class="flex grand-total-row">
    <div class="flex1">Grand Total</div>
    <div class="col-rate"></div>
    <div class="col-qty"></div>
    <div>
      <input nz-input formControlName="grandTotal" [ngClass]="{'readonly': isReadOnly('grandTotal')}">
    </div>
  </div>

  <div class="flex grand-total-row no-padding" *ngIf="totalShipmentCost">
    <div class="flex1">Margin</div>
    <div class="col-rate"></div>
    <div class="col-qty"></div>
    <div>
      {{getMargin()}}
    </div>
  </div>

</form>