import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { FormDeliveryInfo } from './comp';
import { FormAddressUSModule } from '../form-address-us/module';
import { SharedModule } from '@app/admin/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzGridModule,
    NzSelectModule,
    NzInputModule,
    NzIconModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    FormAddressUSModule,
    SharedModule,
  ],
  declarations: [
    FormDeliveryInfo,
  ],
  exports: [
    FormDeliveryInfo,
  ]
})
export class FormDeliveryInfoModule {}