import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Output,
  EventEmitter,
  AfterViewInit,
  Input
} from "@angular/core";

import TabComponent from "../tab/tab.component";
import { TabContentComponent, TabHeaderProgress } from "../interface";

@Component({
  selector: "ngx-tabs",
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export default class TabsComponent implements AfterContentInit, AfterViewInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent<TabContentComponent>>;
  @Output() onTabChanged = new EventEmitter<TabComponent<TabContentComponent>>();
  @Input() validateCanChangeTab: (currentIndex: number, nextIndex: number) => boolean = (index) => true;

  constructor() {
  }

  ngAfterContentInit() {
    this.initTabs();
  }

  ngAfterViewInit() {
    setTimeout(() => this.getActiveTab()?.component?.onTabActive(true), 5);
  }

  private initTabs() {
    let activeTab: TabComponent<TabContentComponent>;
    let arrKeys = [];
    for (let i = 0; i < this.tabCount; i++) {
      let tab = this.tabs.get(i);
      if (arrKeys.includes(tab.id)) {
        // Đảm bảo không có 2 tab trùng id
        throw Error(`Duplicate tab id ${tab.id}`);
      } else {
        arrKeys.push(tab.id);
      }
      if (tab.isActive) {
        // chỉ 1 tab được active, nếu khai báo nhiều thằng active = true thì chỉ lấy thằng đầu tiên, còn lại sẽ bị reset hết
        if (!activeTab) {
          activeTab = tab;
        } else {
          tab.setActive(false);
        }
      }
    }
    if (!activeTab) {
      this.selectTab(0);
    }
  }

  get tabCount(): number {
    return this.tabs.length;
  }

  public get currentIndex() {
    for (let i = 0; i < this.tabCount; i++) {
      if (this.tabs.get(i).isActive) {
        return i;
      }
    }
    return 0;
  }

  selectTab(index: number) {
    const tab: TabComponent<TabContentComponent> = this.getTabByIndex(index);
    if (!this.validateCanChangeTab(this.currentIndex, index)) {
      return;
    }
    const currentActive = this.getActiveTab();
    if (currentActive && currentActive.id == tab.id) {
      return;
    }
    this.tabs.toArray().forEach(it => (it.setActive(it.id == tab.id)));
    currentActive?.component?.onTabActive(false);
    tab.component?.onTabActive(true);
    this.onTabChanged.emit(tab);
  }

  getActiveTab(): TabComponent<TabContentComponent> {
    return this.tabs.filter(it => it.isActive)[0];
  }

  checkDoneTab(index: number): boolean {
    const tab = this.getTabByIndex(index);
    return tab.context.getHeaderProgress(tab.id) == TabHeaderProgress.done
  }

  private validateIndex(index: number) {
    if (index < 0 || index >= this.tabCount) {
      throw Error(`index out of bounds`);
    }
  }

  getTabByIndex(index: number): TabComponent<TabContentComponent> {
    this.validateIndex(index);
    return this.tabs.get(index);
  }

  getTabById(id: string): TabComponent<TabContentComponent> {
    return this.tabs.filter(it => it.id == id)[0];
  }

}