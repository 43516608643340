import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, TemplateRef } from '@angular/core';
@Component({
  selector: '[detail-header]',
  templateUrl: './detail-header.html',
  styleUrls: ['./detail-header.scss'],
})
export class DetailHeaderComponent extends BaseComponent {
  private _moreItems: Array<DetailHeaderMoreItem> = [];
  
  @Input() shouldShowBtnEdit = true;
  @Input() shouldShowBtnSave = false;
  @Input() shouldShowBtnAdd = false;
  @Input() shouldShowBtnRefresh = false;
  @Input() onProgress = false;
  @Input() needUpdate = false;
  @Input() txtHeader: any;
  @Input() get moreItems() { return this._moreItems}
  set moreItems(value) {
    this._moreItems = value;
    this.setupLanguage();
  }
  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMoreItem: EventEmitter<DetailHeaderMoreItem> = new EventEmitter<DetailHeaderMoreItem>();
  
  get shouldShowBtnMore(): boolean {return this.moreItems && this.moreItems.length > 0}
  
  get isTxtHeaderTemplate(): boolean {
    return this.txtHeader && this.txtHeader instanceof TemplateRef;
  }
  
  @ViewChild('btnMore') btnMore: ElementRef<HTMLElement>;
  constructor() {
    super();
  }
  
  setupLanguage() {
    for (let i = 0; i < this.moreItems.length; i++) {
      if (this.moreItems[i].localizedKey) {
        this.moreItems[i].text = this.text(this.moreItems[i].localizedKey);
      }
    }
  }
  
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // if (!event.ctrlKey) {
    //   return;
    // }
    // switch (event.key) {
    //   case 'D':
    //   case 'd':
    //     // Delete
    //     if (this.shouldShowBtnEdit) {
    //       this.onBtnDel();
    //     }
    //     break;
    //   case 'E':
    //   case 'e':
    //     // Edit
    //     if (this.shouldShowBtnEdit) {
    //       this.onBtnEdit();
    //     }
    //     break;
    //   case 'R':
    //   case 'r':
    //     // Refresh
    //     if (this.shouldShowBtnEdit) {
    //       this.onBtnRefresh();
    //     }
    //     break;
    //   case 'N':
    //   case 'n':
    //     if (this.shouldShowBtnAdd) {
    //       this.onBtnAdd();
    //     }
    //     break;
    //   case 'Esc':
    //   case 'Escape':
    //     // Cancel
    //     if (this.shouldShowBtnSave) {
    //       this.onBtnCancel();
    //     }
    //   case 'S':
    //   case 's':
    //     // Save
    //     if (this.shouldShowBtnSave) {
    //       this.onBtnSave();
    //     }
    //     break;
    //   case 'M':
    //   case 'm':
    //     if (this.shouldShowBtnMore && this.btnMore) {
    //       this.btnMore.nativeElement.click();
    //     }
    // }
  }
  
  onBtnRefresh() {
    this.onRefresh.emit();
  }
  
  onBtnAdd() {
    this.onAdd.emit();
  }
  
  onBtnEdit() {
    this.onEdit.emit();
  }
  
  onBtnDel() {
    this.onDelete.emit();
  }
  
  onBtnSave() {
    this.onSave.emit();
  }
  
  onBtnCancel() {
    this.onCancel.emit();
  }
  
  onMoreItemClick(item: DetailHeaderMoreItem) {
    this.onMoreItem.emit(item);
  }
}