import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MasterData } from '@services/master.data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormItem } from '@app/admin/base/form-item';
import { InputHelper } from '@services/input-helper';
import { FuelCostType, VolumeDiscountType } from '@app/enum';
import { Const } from '@const/Const';
import { RoleManager } from '@services/role-manager';
import { Utils } from '@services/utils';
import { ServiceOptionType } from '@wearewarp/types/rest-api';

function isUsdConversionChanged(value1, value2) {
  if (Utils.isNumber(value1) && Utils.isNumber(value2) && value1 !== value2) return true;
  return false;
}

@Component({
  selector: '[form-input-cost]',
  templateUrl: './comp.html',
  styleUrls: ['./style.scss', "../../../../styles/row-col.scss"]
})
export class FormInputCost extends BaseFormItem {
  private _isEnabled = true;
  @Input() get isEnabled() {return this._isEnabled}
  set isEnabled(value) {
    this._isEnabled = value;
    this.setEnableFormGroup(value);
  }
  @Input() origin: 'shipmentForm'|'assignCarrierForm' = 'shipmentForm';
  @Input() contentView: TemplateRef<any>;
  @Input() set form(value: FormGroup) {
    this.formInput = value;
    this.keys = Object.keys(this.formInput.controls);
  }
  @Input() showSubTotal: boolean = true;
  @Input() showVolumeDiscount: boolean = true;
  @Output() serviceOptionsChange = new EventEmitter<any>();
  lastCurrencyType = 'USD';

  public static makeDeclaration(symbol: string): FormGroupDeclaration {
    return {
      currency: {label: 'Transit Cost', required: false, type: 'formGroup', childItem: {
        type: {label: 'Currency', initialValue: Const.CurrencyConfig.USD.value},
        fxRate: {label: 'FX Rate', type: 'number', required: symbol !== "$" ? true : false, placeHolder: 'FX Rate'},
      }},
      transitCost: {label: 'Transit Cost', required: false, type: 'formGroup', childItem: {
        rate: {label: '', required: false, type: 'number', placeHolder: symbol, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol)},
        qty: {label: '', type: 'number', required: false, initialValue: 1},
        total: {label: '', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol)}
      }},
      volumeDiscount: {label: 'Volume Discount', type: 'formGroup', childItem: {
        type: {label: '', initialValue: VolumeDiscountType.percentage},
        percentage: {label: '%', type: 'number', placeHolder: '%', getValue: InputHelper.getValuePercentage, formatValue: InputHelper.formatPercentage},
        flatRate: {label: 'Flat Rate', type: 'number', placeHolder: symbol, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol)},
        qty: {label: '', type: 'number', readOnly: true, submitReadOnly: true},
        total: {label: '', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol)}
      }},
      subTotal: {label: '', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol)},
      fuelCost: {label: 'Fuel Cost', type: 'formGroup', childItem: {
        type: {label: '', initialValue: FuelCostType.ratePerMile},
        percentage: {label: '%', type: 'number', placeHolder: '%', getValue: InputHelper.getValuePercentage, formatValue: InputHelper.formatPercentage},
        rpm: {label: 'RPM', type: 'number', placeHolder: symbol, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol)},
        qty: {label: '', type: 'number'},  // only if choose RPM (rate per mile)
        total: {label: '', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol)}
      }},
      serviceOptions: {label: 'Service Options', type: 'formArray', childItem: {
        _id: {label: ''},
        dropOffIndex : {label: '',type: 'number',initialValue: -1},
        rate: {label: '', required: false, type: 'number', placeHolder: symbol, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol)},
        qty: {label: '', initialValue: 1, type: 'number', getValue: InputHelper.getValueMoney },
        total: {label: '', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol)}
      }},
      negativeMarginReason: { label: "Negative Margin Reason" },
      manager: { label: "Manager" },
      grandTotal: {label: 'Grand Total', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value)},
      usdConversion: {label: 'USD Conversion', type: 'number', isChanged: isUsdConversionChanged, readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol)}
    }
  }

  public static get declaration(): FormGroupDeclaration {
    return this.makeDeclaration('');
  }

  protected formGroupDeclaration: FormGroupDeclaration = FormInputCost.declaration;
  private keys = [];
  get formInputKeys(): Array<string> {
    return this.keys;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  // The keys 'rate', 'qty', 'total' should not be changed
  public rows: {[key: string]: FormRow} = {
    transitCost: {columns: [
      {key: 'transitCost'},
      {key: 'rate'},
      {key: 'qty'},
      {key: 'total'},
    ]},
    volumeDiscount: {columns: [
      {key: 'volumeDiscount'},
      {key: 'rate'},
      {key: 'qty'},
      {key: 'total'},
    ]},
    fuelCost: {columns: [
      {key: 'fuelCost'},
      {key: 'rate'},
      {key: 'qty'},
      {key: 'total'},
    ]},
    serviceOptions: {columns: [
      {key: '_id'},
      {key: 'dropOffIndex'},
      {key: 'rate'},
      {key: 'qty'},
      {key: 'total'},
    ]}
  }

  @Input() serviceOptions = []

  get fuelCostType() {
    if (!this.formInput) return '';
    return this.formInput.get('fuelCost').get('type').value;
  }

  get volumeDiscountType() {
    if (!this.formInput) return '';
    return this.formInput.get('volumeDiscount').get('type').value;
  }

  get qty(): number {
    return this.getItemValue('transitCost.qty') ?? 0;
  }

  getLabelDropInfoIndex(index): string {
    let dropOffIndex = this.getItemValue(`serviceOptions[${index}].dropOffIndex`);
    let itemId = this.getItemValue(`serviceOptions[${index}]._id`);
    let option = MasterData.getServiceOptionById(itemId);
    if (option && option.type == 'delivery' && dropOffIndex > -1) return `Delivery Location ${dropOffIndex+1}`;
    return "";
  }

  ngOnInit(): void {
    if (this.serviceOptions.length == 0) {
      this.serviceOptions = [
        {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
        {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
        {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
      ];
    }

    super.ngOnInit();
    if (this.isAdminReadOnlyRole){
      this.isEnabled = false;
    }

    this.setEnableFormGroup(this.isEnabled);
    this.updateCost()
  }

  protected setEnableFormGroup(enable: boolean) {
    super.setEnableFormGroup(enable);
    if (!this.model?.currency?.type || this.model?.currency?.type === Const.CurrencyConfig.USD.value) {
      this.formInput?.get('currency')?.get("fxRate").disable();
    }
  }

  get symbol() {
    return ''
  }

  protected beforeBindModel(model): any {
    this.lastCurrencyType = model?.currency?.type || 'USD'
    return model;
  }

  protected createFormInput(bindData?: any): void {
    if (this.formInput) {
      const symbol = this.symbol;
      this.formGroupDeclaration = FormInputCost.makeDeclaration(symbol);
      if (bindData) this.bindDataModel(bindData);
    } else {
      super.createFormInput(bindData);
    }
  }

  // Nếu đi từ màn ShipmentDetail thì quantity được tính từ tổng số item rồi truyền vào,
  // Nếu đi từ dialog AssignCarrier thì ko truyền quantity mà lấy từ input.
  public updateCost(quantity: number = null) {
    let qty = this.getItemValue('transitCost.qty')
    // 20220809-cmt by Phuong: WPD-359 Change default Transit Cost Qty to 1 instead of the count of units
    // if (typeof quantity === 'number') {
    //   qty = quantity;
    //   this.setItemValue('transitCost.qty', qty);
    // } else {
    //   qty = this.getItemValue('transitCost.qty');
    // }

    // let qty = this.getItemValue('transitCost.qty');
    // if (!qty) {
    //   qty = 0;
    //   // for (let i = 0; i < this.itemCount; i++) {
    //   //   let item = this.getListItemAt(i);
    //   //   qty += item.qty;
    //   // }
    //   this.setItemValue('transitCost.qty', qty);
    // }

    let rate = this.getItemValue('transitCost.rate');
    let transitCostTotal = qty * rate;
    this.setItemValue('transitCost.total', transitCostTotal);

    // Calculate discount & sub total
    let volumeDiscountType = this.getItemValue('volumeDiscount.type');
    let discountTotal = 0;
    if (volumeDiscountType == VolumeDiscountType.flatRate) {
      const flatRate = this.getItemValue('volumeDiscount.flatRate');
      if (flatRate) discountTotal = flatRate;
    } else if (volumeDiscountType == VolumeDiscountType.percentage) {
      let discountPercent = this.getItemValue('volumeDiscount.percentage') / 100;
      discountTotal = discountPercent * transitCostTotal;
    }
    let subTotal = transitCostTotal - discountTotal;
    this.setItemValue('volumeDiscount.total', discountTotal);
    this.setItemValue('subTotal', subTotal);

    // Calculate grand total
    let fuelCostType = this.getItemValue('fuelCost.type');
    let fuelTotal;
    if (fuelCostType == FuelCostType.ratePerMile) {
      let fuelCostRate = this.getItemValue('fuelCost.rpm');
      let fuelCostQty = this.getItemValue('fuelCost.qty');
      fuelTotal = fuelCostRate * fuelCostQty;
    } else if (fuelCostType == FuelCostType.percentage) {
      let fuelCostPercent = this.getItemValue('fuelCost.percentage') / 100;
      fuelTotal = fuelCostPercent * subTotal;
    }
    this.setItemValue('fuelCost.total', fuelTotal);
    let grandTotal = subTotal + fuelTotal;
    for (let i = 0; i < this.getArrayControls('serviceOptions').length; i++) {
      const serviceOptionId = this.getItemValue(`serviceOptions[${i}]._id`);
      if (!serviceOptionId) continue;
      let rate = this.getItemValue(`serviceOptions[${i}].rate`);
      let qty = this.getItemValue(`serviceOptions[${i}].qty`);
      if (rate && qty) {
        let cost = qty * rate;
        this.setItemValue(`serviceOptions[${i}].total`, cost);
      }
      let serviceOptionCost = this.getItemValue(`serviceOptions[${i}].total`);
      if (!MasterData.isServiceOptionTypeNegative(serviceOptionId)) {
        grandTotal += serviceOptionCost;
      } else {
        grandTotal -= serviceOptionCost;
      }
    }
    if (grandTotal < 0) grandTotal = 0;
    this.setItemValue('grandTotal', grandTotal);
    this.getMargin();
    let fxRate = this.getItemValue('currency.fxRate');
    this.setItemValue('usdConversion', grandTotal * fxRate);
    this.checkNegativeMargin(grandTotal)
  }

  private checkNegativeMargin(costTotal:number){
    if(this.totalShipmentCost)
      this.isNegativeMargin = costTotal>= this.totalShipmentCost
    if(this.isNegativeMargin){
      this.formInput?.get("negativeMarginReason")?.setValidators([Validators.required]);
      this.formInput?.get("negativeMarginReason")?.updateValueAndValidity();
      this.formInput?.get("manager")?.setValidators([Validators.required]);
      this.formInput?.get("manager")?.updateValueAndValidity();
      if(!this.managers){
        this.getManagersData()
      }
    } else{
      this.formInput?.get("negativeMarginReason")?.clearValidators();
      this.formInput?.get("negativeMarginReason")?.updateValueAndValidity();
      this.formInput?.get("manager")?.clearValidators();
      this.formInput?.get("manager")?.updateValueAndValidity();
    }
  }

  onFuelCostTypeChange(value) {
    if (value == FuelCostType.ratePerMile) {
      this.setItemValue('fuelCost.percentage', null);
    } else if (value == FuelCostType.percentage) {
      this.setItemValue('fuelCost.rpm', null);
      this.setItemValue('fuelCost.qty', null);
    }
    this.setItemValue('fuelCost.total', 0);
    this.updateCost();
  }

  onVolumeDiscountTypeChange(value) {
    if (value == VolumeDiscountType.flatRate) {
      this.setItemValue('volumeDiscount.percentage', null);
    } else if (value == VolumeDiscountType.percentage) {
      this.setItemValue('volumeDiscount.flatRate', null);
    }
    this.setItemValue('volumeDiscount.total', 0);
    this.updateCost();
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'transitCost.qty':
      case 'fuelCost.qty':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key), {isInteger: true});
      case 'serviceOptions.qty':
        return InputHelper.handleInputChangDecimalNumber(event, <FormControl>this.formInput.get(key));
      case 'transitCost.rate':
      case 'serviceOptions.rate':
      case 'fuelCost.rpm':
      case 'volumeDiscount.flatRate':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true, this.symbol);
      case 'volumeDiscount.percentage':
      case 'fuelCost.percentage':
        return InputHelper.handleInputChangePercentage(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'transitCost.qty':
      case 'fuelCost.qty':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'serviceOptions.qty':
        return InputHelper.handleInputKeyPressDecimalNumber(event);
      case 'transitCost.rate':
      case 'serviceOptions.rate':
      case 'fuelCost.rpm':
      case 'volumeDiscount.flatRate':
        return InputHelper.handleInputKeyPressMoney(event);
      case 'volumeDiscount.percentage':
      case 'fuelCost.percentage':
        return InputHelper.handleInputKeyPressPercentage(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputFocusOut(event, key) {
    switch (key) {
      case 'transitCost.rate':
      case 'transitCost.qty':
      case 'volumeDiscount.rate':
      case 'fuelCost.rpm':
      case 'fuelCost.qty':
      case 'fuelCost.percentage':
      case 'volumeDiscount.percentage':
        return this.updateCost();
      case 'volumeDiscount.flatRate':
        let msg = this.validateDiscountValue();
        if (msg) {
          this.showDialog(msg);
          this.setItemValue('volumeDiscount.flatRate', null);
          return;
        }
        return this.updateCost();
    }
    if (/^serviceOptions\[[0-9]+\].rate/.test(key)) {
      let rate = this.getItemValue(key);
      let keyTotal = key.replace(/rate$/, 'total')
      let keyQty = key.replace(/rate$/, 'qty')
      let qty = this.getItemValue(keyQty);
      let serviceOptionCost = qty * rate;
      this.setItemValue(keyTotal, serviceOptionCost);
      this.updateCost();
    }
    if (/^serviceOptions\[[0-9]+\].qty/.test(key)) {
      let qty = this.getItemValue(key);
      let keyTotal = key.replace(/qty$/, 'total')
      let keyRate = key.replace(/qty$/, 'rate')
      let rate = this.getItemValue(keyRate);
      let serviceOptionCost = qty * rate;
      this.setItemValue(keyTotal, serviceOptionCost);
      this.updateCost();
    }
  }

  protected validateDiscountValue(): string {
    let transitCostTotal = this.getItemValue('transitCost.total');
    if (!transitCostTotal) {
      return 'Please enter transit cost first';
    }
    let discountValue = this.getItemValue('volumeDiscount.flatRate');
    if (discountValue && discountValue > transitCostTotal) {
      return 'Discount must be less than total transit cost';
    }
    return null;
  }

  onBtnAddServiceOption() {
    if (!this.isEnabled) {
      return;
    }
    this.addItemToFormArray('serviceOptions');
  }

  onBtnRemoveServiceOption(index: number) {
    if (!this.isEnabled) {
      return;
    }
    let itemId = this.getItemValue(`serviceOptions[${index}]._id`);
    if (itemId) {
      let itemName = this.getServiceOptionName(itemId);
      this.confirmDeletion({
        message: `Remove <b>${itemName}</b>?`,
        fnOk: () => {
          this.removeItemInFormArray('serviceOptions', index);
          this.serviceOptionsChange.emit();
          this.updateCost();
        }
      })
    } else {
      this.removeItemInFormArray('serviceOptions', index);
    }
  }

  onServiceOptionItemChange(index: number, event) {
    setTimeout(() => this.serviceOptionsChange.emit(), 1);
    this.updateCost();
  }

  isDisableServiceOptionItem(item: ServiceOptionType, currentServiceIndex: number): boolean {
    if (this.origin != 'shipmentForm') {
      let arr = this.getItemValue('serviceOptions');
      for (let obj of arr) {
        if (obj._id == item._id) {
          return true;
        }
      }
      return false;
    }
    const currentServiceId = this.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    const currentServiceDropOffIndex = this.getItemValue(`serviceOptions[${currentServiceIndex}].dropOffIndex`);
    let currentService = MasterData.getServiceOptionById(currentServiceId);
    if (currentService && currentService.type == 'delivery') {
      // Disable type la Pickup va addition
      if (item.type != "delivery" ) {
        return true;
      } else {
        let arr = this.getItemValue('serviceOptions');
        arr = arr.filter(obj => obj.dropOffIndex == currentServiceDropOffIndex);
        for (let obj of arr) {
          if (obj._id == item._id) {
            return true;
          }
        }
      }
    } else {
      // Trường hợp multi DropOff, không cho chọn service từ cost, chỉ chọn select từ DropOff 1,2,3...
      for (let group of this.serviceOptions) {
        if (group.label == 'Delivery services') {
          for (let obj of group.items) {
            if (obj._id == item._id) return true;
          }
        }
      }
      let arr = this.getItemValue('serviceOptions');
      for (let obj of arr) {
        if (obj._id == item._id) {
          return true;
        }
      }
    }
    return false;
  }

  isSeviceOptionTypeNegative(currentServiceIndex) {
    const currentServiceId = this.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    if (MasterData.isServiceOptionTypeNegative(currentServiceId)) return true;
    return false;
  }

  getServiceOptionMoneyValue(currentServiceIndex) {
    const total = this.getItemValue(`serviceOptions[${currentServiceIndex}].total`);
    return InputHelper.formatMoney2(total, this.symbol);
  }

  @Input() totalShipmentCost: number = undefined;
  isNegativeMargin: boolean;
  reasons = MasterData.getCostNegativeMarginReasons();
  isManagerLoading=false
  managers:Array<any> = undefined

  private getManagersData() {
    this.isManagerLoading = true;
    let filter={roleIds:[RoleManager.adminId]}
    let url = `${Const.APIURI_USERS}?skip=0&limit=-1&filter=${JSON.stringify(filter)}`;
    this.api.GET(url).subscribe(
      resp => {
        this.isManagerLoading = false;
        this.managers=resp.data.list_data
      }, err => {
        this.showErr(err)
        this.isManagerLoading = false;
      }
    );
  }

  public getRevenue() {
    if(this.totalShipmentCost){
      return InputHelper.formatMoney2(this.totalShipmentCost.toString())
    }
    return 'N/A';
  }

  public getMargin() {
    if(this.totalShipmentCost){
      let margin = Math.round((1 - this.getItemValue('grandTotal')/this.totalShipmentCost)*100);
      let marginPercentage = margin<0 ? "-" : "";
      marginPercentage += InputHelper.formatPercentage(margin.toString());
      return marginPercentage;
    }
    return 'N/A';
  }
}
