import { BaseList } from "../base/list";
import { Component, EventEmitter } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { DialogService } from "@dialogs/dialog.service";
import { DetailAuditLogComponent } from "./components/detail.component";
@Component({
  selector: "carrier-bid-list",
  templateUrl: "./list.html",
  styleUrls: ["../list.scss"],
})
export class AuditLogList extends BaseList {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }
  public logEntities = [];
  public logUsers = [];
  public selectedEntity = null;
  public selectedUser = null;
  public filter = {} as any;

  ngOnInit(): void {
    super.ngOnInit();
    const filter = this.getQueryParam("filter")
    try {
      if(filter){
        this.selectedEntity = JSON.parse(filter).entity
        this.selectedUser = JSON.parse(filter).userId
      }
    } catch {}
  }

  protected getApiUrl(): string {
    return `${Const.APIURI_AUDIT_LOGS}/getall`;
  }
  
  protected onGetDataSucceeded(res){
    this.logEntities = res?.data?.logEntities;
    this.logUsers = res?.data?.logUsers;
    for (let item of this.listData) {
      if (item.details) {
        item.detailJson = JSON.stringify(item.details, null, 2);
      }
    }
  }
  
  public formatDate(date) {
    return DateUtil.format(date, Const.DATETIME_FORMAT_FROM_DB);
  }
  
  public onSearchEntity(entity){
    if(entity) this.filter.entity = entity
    else delete this.filter.entity;
    this.routeWithQueryUrl({filter: JSON.stringify(this.filter)}, true)
  }
  
  public onSearchUser(userId){
    if(userId) {
      const user = this.logUsers.find(item => item._id == userId)
      if(user){
        this.filter.userId = user._id;
        this.filter.userCollection = user.collection;
      }
    }
    else {
      delete this.filter.userId;
      delete this.filter.userCollection;
    }
    this.routeWithQueryUrl({filter: JSON.stringify(this.filter)}, true)
  }
  
  public onClickDetail(item){
    item.isShowingDetail = true;
    let afterClose = new EventEmitter<any>();
    afterClose.subscribe(() => item.isShowingDetail = false);
    DialogService.openFormDialog1(DetailAuditLogComponent, {
      nzComponentParams: {
        data: item,
        closeOnSuccess: true,
      },
      nzClassName: "modal modal-lg",
      nzAfterClose: afterClose,
    });
  }

  public onExpandChange(item: any, checked: boolean) {
    item.isExpand = checked;
  }
}
