import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "form-input-upload-file",
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class FormInputUploadFile extends BaseFormItem implements OnInit {
  @Input() visible;
  @Input() textSelectFile = 'Select File';
  @Input() nzIconSelectFile = 'paper-clip';
  @Output() fileSelectedChange = new EventEmitter();

  isLoading: boolean = true;
  isUploading: boolean = false;
  isError: boolean = false;
  selectedFiles = [];
  constructor() {
    super();
  }

  ngOnInit(): void {}

  public resetFormInput() {
    this.selectedFiles = [];
  }

  onFileSelected(inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = "";
    this.selectedFiles.push({
      file,
      fileName: file.name,
      size: file.size
    });
    this.fileSelectedChange.emit(this.selectedFiles);
  }

  onBtnDelImage(index) {
    for (let i=index; i < this.selectedFiles.length - 1; i++) {
      let tmp = this.selectedFiles[i];
      this.selectedFiles[i] = this.selectedFiles[i+1];
      this.selectedFiles[i+1] = tmp;
    }
    this.selectedFiles.pop();
    this.fileSelectedChange.emit(this.selectedFiles);
  }
}
