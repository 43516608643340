<form nz-form [formGroup]="formInput" class="form-delivery-info">
  <div nz-row [nzGutter]="{ md: 32 }">
    <div nz-col *ngFor="let key of ['locationName']" nzXs="24" nzMd="12">
      <div class="form-label-v2">
        {{getLabel(key)}}
      </div>
      <nz-form-item>
        <nz-form-control>
          <ng-container>
            <input nz-input [formControlName]="key"
            [nzAutocomplete]="autoComplete1"
            (ngModelChange)="onValueChange($event)">
            <nz-autocomplete #autoComplete1 [nzDefaultActiveFirstOption]="false">
              <nz-auto-option *ngFor="let item of locationsFiltered; let i = index" 
                [nzValue]="item.name" (selectionChange)="onLocationSelected($event, item)">
                {{item.name}}
              </nz-auto-option> 
            </nz-autocomplete>
          </ng-container>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="bottom20">
    <div class="form-label-v2">
      {{getLabel('addr')}}
    </div>
    <form-address formControlName="addr"></form-address>
  </div>
  
  <div nz-row [nzGutter]="{ md: 32}">
    <div nz-col nzXs="24" nzMd="12">
      <div class="flex gap15">
        <div *ngFor="let key of ['contactName']" class="flex1">
          <div class="form-label-v2">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input [formControlName]="key" 
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)" >
            </nz-form-control>
          </nz-form-item>
        </div>
        <div *ngFor="let key of ['contactPhone']" style="width: 150px;">
          <div class="form-label-v2">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input [formControlName]="key" 
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)" 
                (keypress)="onInputKeyPress($event, key)" >
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div *ngFor="let key of ['contactEmail']" nz-col nzXs="24" nzMd="12">
      <div class="form-label-v2">
        {{getLabel(key)}}
        <span *ngIf="isRequired(key)" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control> 
          <input nz-input [formControlName]="key" 
            [type]="getInputType(key)" 
            [placeholder]="getPlaceHolder(key)"
            (input)="onInputChanged($event, key)" 
            (keypress)="onInputKeyPress($event, key)" >
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="{ md: 32}">
    <div nz-col nzXs="24" nzMd="12">
      <ng-container [ngTemplateOutlet]="tplTimeWindows" 
        [ngTemplateOutletContext]="{key: 'windows', form: formInput}">
      </ng-container>
    </div>
    <div class="flex gap20" nz-col nzXs="24" nzMd="12">
      <div *ngFor="let key of ['accessCode']" class="flex1">
        <div class="form-label-v2 single-line fix-height">
          {{getLabel(key)}}
          <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <input *ngIf="!isMultiline(key)"
              nz-input [formControlName]="key"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)" >
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
  <div nz-row [nzGutter]="{ md: 32}">
    <ng-container *ngFor="let key of ['instructions', 'note']">
      <div nz-col nzXs="24" nzMd="12">
        <div class="form-label-v2 single-line fix-height">
          {{getLabel(key)}}
          <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item class="ref-num">
          <nz-form-control>
            <input *ngIf="!isMultiline(key)"
              nz-input [formControlName]="key"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)" >
            <textarea *ngIf="isMultiline(key)"
              nz-input [formControlName]="key"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)"
              [nzAutosize]="{minRows: 2, maxRows: 4}">
            </textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>
</form>

<ng-template #tplTimeWindows let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <ng-container [formArrayName]="key">
      <form *ngFor="let item of getControlTimeWindows(); let arrIndex = index" nz-form [formGroupName]="arrIndex" class="pick-up-time">
        <div class="select-date-time">
          <div *ngFor="let childKey of ['date', 'fromTime', 'toTime']; let i = index" [ngClass]="{'flex1': i == 0, 'right20': i == 0}">
            <div class="form-label-v2 single-line fix-height">
              {{getLabel(fullKey(key, childKey))}}
              <span *ngIf="isRequired(fullKey(key, childKey)) && i < 2" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker *ngIf="childKey == 'date'" [formControlName]="childKey" nzFormat="yyyy-MM-dd"style="width: 100%;"></nz-date-picker>
                <nz-time-picker [nzMinuteStep]="15" *ngIf="childKey != 'date'" [formControlName]="childKey" nzFormat="HH:mm" 
                  [nzPlaceHolder]="getPlaceHolder(fullKey(key, childKey))" [ngClass]="childKey"
                  (ngModelChange)="onSelectTimeChange(childKey, $event)">
                </nz-time-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>
</ng-template>