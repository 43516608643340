<form nz-form [formGroup]="formInput" class="pick-up-time">
  <div class="select-date-time">
    <div *ngFor="let key of ['date', 'fromTime', 'toTime']; let i = index" [ngClass]="{'flex1': i == 0, 'right20': i == 0}">
      <div class="form-label-v2 single-line fix-height">{{getLabel(key)}}<span *ngIf="isRequired(key) && i < 2" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <nz-date-picker *ngIf="key == 'date'" style="width: 100%;"
            [formControlName]="key" nzFormat="yyyy-MM-dd"
            (ngModelChange)="onFormDataChange(key, $event)"></nz-date-picker>
          <nz-time-picker [nzMinuteStep]="15" *ngIf="key != 'date'" [ngClass]="key"
            [formControlName]="key" nzFormat="HH:mm" 
            [nzPlaceHolder]="getPlaceHolder(key)"
            (ngModelChange)="onFormDataChange(key, $event)">
          </nz-time-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>