import { Directive, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Utils } from '@services/utils';
import { Log } from '@app/services/log';
import { ComponentCheckCanQuit } from '@services/check-can-quit';
import { KeyLang } from '@locale/index';
import { BaseFormItem } from './form-item';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';

@Directive()
export abstract class BaseDetail<T = any> extends BaseFormItem<T> implements ComponentCheckCanQuit {
  protected _id = '';
  get id() { return this._id }
  
  private resetForm = false;
  protected location: Location;

  get shouldCreateFormImmediately() { return false}
  
  get shouldShowForm() {
    if (this.resetForm) return false;
    return this.isCreateNew || (!this.isCreateNew && this.model);
  }

  get shouldShowFirstLoading() {
    return this.onProgress && !this.model;
  }
  
  get shouldEnableInputForm(): boolean {
    return this.isEditing || this.isCreateNew;
  }
  
  public canDragDrop(list: Array<any>) {
    return list && list.length > 1 && this.isEditing;
  }

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.location = this.injector.get(Location);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
  
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.handleKeyEvent(event);
  }

  protected handleKeyEvent(event: KeyboardEvent) {
    if (!event.ctrlKey) {
      return;
    }
    switch (event.key) {
      case 'R':
      case 'r':
        // Refresh
        if (this.shouldShowBtnEdit) {
          this.onBtnRefresh();
        }
        break;
      case 'E':
      case 'e':
        // Edit
        if (this.shouldShowBtnEdit) {
          this.onBtnEdit();
        }
        break;
      case 'Esc':
      case 'Escape':
        // Cancel
        if (this.shouldShowBtnSave) {
          this.onBtnCancel();
        }
      case 'S':
      case 's':
        // Save
        if (this.shouldShowBtnSave) {
          this.onBtnSave();
        }
        break;
    }
  }

  protected getTextCreateNew(): string {
    return 'Create new';
  }
  protected getPageTitle(model): string {
    if (model.name) {
      return model.name;        // artist, album
    } else if (model.title) {
      return model.title;       // song
    }
    return '';
  }

  // Chỉ dùng sau khi update
  protected bindDataModel(model) {
    super.bindDataModel(model);
  }
  
  protected getMsgConfirmDelete(): string {
    return this.text(KeyLang.Txt_ConfirmDelete);
  }
  
  protected canEdit(model): boolean {
    return true;
  }
  
  protected canDelete(model): boolean {
    Log.w('To avoid risk, sub classes must override canDelete() and return true to allow deleting');
    return false;
  }
  
  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    let prevId = this.id;
    this._id = this.getIdForDetailComponent(this.activatedRoute) || (this.currentUrl.includes('/add') ? 'add' : null);
    if (prevId == this.id) {
      return;
    }
    this.reset();
    this.isEditing = false;
    this.stopProgress();
    // Khi đang có model mà back lại để add tiếp, mặc dù khởi tạo lại form nhưng view vẫn có 1 vài form item dính dữ liệu cũ (khả năng là bug của thư viện)
    // Đoạn này mục đích để cho view nó render hoàn toàn mới lại thì sẽ OK.
    this.resetForm = true;
    Log.d(`[${this.TAG}] handleNavigationEnd ${url}`);
    setTimeout(() => this.resetForm = false, 100);
    if (this.isCreateNew) {
      if (this.isAdminReadOnlyRole){
        let redirectUrl = this.currentUrl.substring(0,this.currentUrl.lastIndexOf('/add'));
        this.router.navigate([redirectUrl]);
      }
      this.model = null;
      this.formInput = null;
      this.createFormInput();
      this.setEnableFormGroup(true);
    } else {
      this.getData();
      this.checkLogHistory();
    }
  }
  
  get isCreateNew(): boolean {
    return this.id === 'add';
  }
  get isReadonly(): boolean {
    return !this.isCreateNew && !this.isEditing;
  }
  get txtHeader() {
    if (!this) {
      return '';
    }
    return this.isCreateNew ? this.getTextCreateNew() : (this.model && (<any>this.model)?.name ? (<any>this.model)?.name : '');
  }
  get shouldShowBtnEdit() {
    return this && !this.isEditOrCreate && !this.onProgress;
  }
  get shouldShowBtnSave() {
    return this && this.isEditOrCreate && !this.onProgress;
  }
  get shouldShowBtnAdd() {
    return this && !this.isCreateNew && !this.isEditing && !this.onProgress;
  }
  get shouldShowBtnRefresh() {
    return !this.onProgress && !this.isEditOrCreate;
  }

  onBtnAdd() {
    if (this.isCreateNew) {
      return;
    }
    let url = this.getCurrentUrlPath();
    let arr = this.getUrlSegments();
    arr[arr.length - 1] = 'add';
    url = arr.join('/');
    this.reset();
    this.router.navigate([url]);
  }
  
  onBtnRefresh() {
    if (this.isCreateNew) {
      return;
    }
    this.getData();
  }

  onBtnEdit() {
    super.onBtnEdit();
  }

  onBtnDel() {
    if (!this.canDelete(this.model)) {
      return;
    }
    this.confirmDeletion({
      message: this.getMsgConfirmDelete(),
      txtBtnOk: this.txtDelete,
      fnOk: () => this.deleteData()
    });
  }

  protected doCancel() {
    if (this.isCreateNew) {
      this.reset();
      this.createFormInput();
      this.location.back();
    } else {
      this.isEditing = false;
      if (!this.model) {
        for (let key of Object.keys(this.formInput.controls)) {
          this.formInput.get(key).setValue('');
          this.formInput.get(key).enable();
        }
      } else {
        this.reset();
        this.formInput = null;
        this.createFormInput(this.model);
        setTimeout(() => this.setEnableFormGroup(false), 50);
      }
    }
  }

  onBtnCancel() {
    if (this.needUpdate) {
      return this.modalService.confirm({
        nzContent: this.text(KeyLang.Txt_ConfirmCancel),
        nzOkText: this.text(KeyLang.Txt_ConfirmCancelYes),
        nzCancelText: this.text(KeyLang.Txt_ConfirmCancelNo),
        nzOnOk: () => {
          this.doCancel();
        },
        nzClassName: 'modal-confirm-cancel modal-admin',
        nzWrapClassName: 'vertical-center-modal',
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false,
      });
    } else {
      this.doCancel();
    }
  }

  protected onGetDetailSuccess(data: T): T {
    return data
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.doCancel();
  }

  protected onDeleteSuccess(resp) {
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showInfo('Deleted successfully.');
    }
    this.location.back();
  }
  
  protected routeParam() {
    return null;
  }
  
  protected getIdFromModel(model) {
    return model._id;
  }
  protected handleUpdateMissingModelData() {
  }

  protected buildUrl(method: ApiMethod): string {
    let url = this.getApiUrl(method);
    if (!url) return url;
    if (method == ApiMethod.get || method == ApiMethod.put || method == ApiMethod.delete) {
      let id = this.id || (<any>this.model)?._id;
      if (id != 'add') {
        url = `${url}/${id}`;
      }
    }
    return url;
  }

  protected getData() {
    let url = this.buildUrl(ApiMethod.get);
    if (!url) {
      return Log.e(`[${this.TAG}] getApiUrl is empty`);
    }
    this.startProgress();
    let routeParams = this.routeParam();
    if (Utils.isObjectNotEmpty(routeParams)) {
      let qs = new URLSearchParams(routeParams).toString();
      url += '?' + qs;
    }
    this.api.GET(url).subscribe(
      resp => {
        this.model = this.onGetDetailSuccess(resp.data);
        Log.d('getData model ', this.model);
        // this.bindDataModel(this.model);
        this.createFormInput(this.model);
        this.setEnableFormGroup(false);
        this.stopProgress();
        this.handleHashAfterGotData();
        this.handleUpdateMissingModelData();
      }, err => {
        this.showErr(err);
        this.stopProgress()
      }
    );
  }

  protected handleHashAfterGotData() {
    // Nếu trên URL có hash để nhảy đến element mong muốn, mà phải đợi getData xong thì view mới được render
    // Do đó phải đợi getData xong, đợi cho view nó render xong, rồi xử lý tiếp
    let arr = this.router.url.split('#');
    if (arr.length > 0) {
      let fragment = arr[arr.length - 1];
      if (fragment) {
        setTimeout(() => {
          let element = document.getElementById(fragment);
          this.scrollToElement(element);
        }, 500);
      }
    }
  }
  
  // resp.status: http status code
  // resp.body: json data
  protected handleCreateDataResponse(resp) {
    Log.e('Sub class must override handleCreateDataResponse');
  }
  // resp.status: http status code
  // resp.body: json data
  protected handleUpdateDataResponse(resp) {
    Log.e('Sub class must override handleUpdateDataResponse');
  }

  protected onCreateSuccess(resp) {
    super.onCreateSuccess(resp);
    this.goDetailAfterCreated();
  }

  protected onCreateFailure(err) {
    super.onCreateFailure(err);
  }
  
  protected goDetailAfterCreated() {
    // reset form trước khi navigate để tránh bị cảnh báo bởi window:beforeunload
    this.reset();
    this.formInput = null;
    // this.createFormInput();
    // Chỗ này chưa gán lại this.id vì lát nữa trong hàm handleNavigationEnd còn dùng nó để check, sau đó mới gán
    // this.id = this.getIdFromModel(this.model);
    // let url = this.getCurrentUrlPath().replace(/\/add$/, `/${this.model._id}`);
    let url = this.getCurrentUrlPath().replace(/\/add$/, ''); // 2022/03/08 - new spec: go back to list screen after created
    Log.d('createData done -> detail url: ', url);
    this.router.navigateByUrl(url);
  }

  protected deleteData() {
    let url = this.buildUrl(ApiMethod.delete);
    if (!url) {
      return Log.e(`[${this.TAG}] getApiUrl is empty`);
    }
    this.startProgress();
    this.api.DELETE(url).subscribe(
      resp => {
        Log.d('deleteData done ', resp);
        this.onDeleteSuccess(resp);
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  private isHistoryExisting = false;
  protected get crudEntity(): string {return ''}
  public get shouldShowHistory(): boolean {
    return this.isHistoryExisting && this.model != null;
  }
  private subApiHistory;
  
  protected checkLogHistory() {
    this.isHistoryExisting = false;
    this.subApiHistory?.unsubscribe();
    if (this.isCreateNew) {
      return;
    }
    let entity = this.crudEntity;
    let objectId = this.getIdForDetailComponent(this.activatedRoute);
    if (!entity || !objectId) {
      return;
    }
    let url = `${Const.APIURI_AUDIT_LOGS}/one?entity=${entity}&objectId=${objectId}`;
    this.subApiHistory = this.api.GET(url).subscribe(
      resp => {
        this.isHistoryExisting = Utils.isObjectNotEmpty(resp?.data);
      }, err => {
        // skip
      }
    );
  }

  public goToHistory(item, routePath) {
    this.router.navigate([routePath, 'history', item._id], {queryParams: {entity: this.crudEntity}});
  }

}