<div class="name">
  <ng-container *ngIf="isTxtHeaderTemplate">
    <ng-container *ngTemplateOutlet="txtHeader"></ng-container>
  </ng-container>
  <ng-container *ngIf="!isTxtHeaderTemplate">
    {{txtHeader}}
  </ng-container>
</div>
<div class="ic-edit-save">
  <span *ngIf="shouldShowBtnMore" class="fas fa-ellipsis-v edit ic-more" nz-dropdown nzTrigger="click" #btnMore [nzDropdownMenu]="menu"></span>
  <span *ngIf="shouldShowBtnRefresh" class="fas fa-sync edit ic-refresh" (click)="onBtnRefresh()"></span>
  <span *ngIf="shouldShowBtnAdd" class="fas fa-plus edit ic-add" (click)="onBtnAdd()"></span>
  <span *ngIf="shouldShowBtnEdit" class="fa fa-pencil-alt edit ic-edit" (click)="onBtnEdit()"></span>
  <span *ngIf="shouldShowBtnEdit" class="fa fa-trash-alt edit ic-del" (click)="onBtnDel()"></span>
  <button *ngIf="shouldShowBtnSave" class="btn-save" nz-button nzType="primary" (click)="onBtnSave()" [disabled]="!needUpdate"><span class="fa fa-save" style="margin-right: 10px;"></span>{{txtSave}} (^S)</button>
  <button *ngIf="shouldShowBtnSave" nz-button nzType="default" (click)="onBtnCancel()"><span class="fa fa-times" style="margin-right: 10px;"></span>{{txtCancel}} (^Esc)</button>
  <i *ngIf="onProgress" nz-icon nzType="loading" nzTheme="outline"></i>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let item of moreItems" (click)="onMoreItemClick(item)">{{item.text}}</li>
  </ul>
</nz-dropdown-menu>