import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MasterData } from '@services/master.data';
import { FormInputCost } from './comp';
import { Validators } from '@angular/forms';
import { InputHelper } from '@services/input-helper';
import { FormUtil } from "@services/form-util";
import { Const } from '@const/Const';
import { ServiceOptionType } from '@wearewarp/types/rest-api';

@Component({
  selector: '[form-input-cost-v2]',
  templateUrl: './comp-v2.html',
  styleUrls: ['./style.scss']
})
export class FormInputCostV2 extends FormInputCost {
  // FormInputCostV2 for Shipment Screen
  listDeliveryLocationSelect = [];
  currencies = Object.values(Const.CurrencyConfig);
  private _dropoffNumber = 0;
  @Input() set dropoffNumber(value) {
    if (value > 0) {
      this._dropoffNumber = value;
      this.listDeliveryLocationSelect = [];
      for (let i=0; i<value; i++) {
        this.listDeliveryLocationSelect.push({label: `Delivery Location ${i+1}`, value: i});
      }
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  shouldShowInputServiceOptionDropoff(index: number) {
    let itemId = this.getItemValue(`serviceOptions[${index}]._id`);
    let option = MasterData.getServiceOptionById(itemId);
    if (option && option.type == 'delivery') return true;
    return false;
  }

  isDisableServiceOptionItem(item: ServiceOptionType, currentServiceIndex: number): boolean {
    if (this.origin != 'shipmentForm') {
      let arr = this.getItemValue('serviceOptions');
      for (let obj of arr) {
        if (obj._id == item._id) {
          return true;
        }
      }
      return false;
    }
    const currentServiceId = this.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    const currentServiceDropOffIndex = this.getItemValue(`serviceOptions[${currentServiceIndex}].dropOffIndex`);
    let currentService = MasterData.getServiceOptionById(currentServiceId);
    if (!currentService) {
      if (item.type == "delivery" ) {
        let arr = this.getItemValue('serviceOptions');
        let count = 0;
        for (let obj of arr) {
          if (obj._id == item._id) count++;
        }
        if (count >= this._dropoffNumber) return true;
        return false;
      } else {
        // disable những Pickup va addition đã được selected
        let arr = this.getItemValue('serviceOptions');
        for (let obj of arr) {
          if (obj._id == item._id) {
            return true;
          }
        }
        return false;
      }
    } else {
      if (currentService.type == 'delivery') {
        if (item.type == "delivery") {
          let arr = this.getItemValue('serviceOptions');
          let count = 0;
          for (let obj of arr) {
            if (obj._id == item._id) count++;
            if (obj._id == item._id && obj.dropOffIndex == currentServiceDropOffIndex) return true;
          }
          if (count >= this._dropoffNumber) return true;
          return false;
        }
        let arr = this.getItemValue('serviceOptions');
        for (let obj of arr) {
          if (obj._id == item._id) {
            return true;
          }
        }
        return false;
      } else {
        if (item.type == "delivery" ) {
          let arr = this.getItemValue('serviceOptions');
          let count = 0;
          for (let obj of arr) {
            if (obj._id == item._id) count++;
          }
          if (count >= this._dropoffNumber) return true;
          return false;
        } else {
          // disable những Pickup va addition đã được selected
          let arr = this.getItemValue('serviceOptions');
          for (let obj of arr) {
            if (obj._id == item._id) {
              return true;
            }
          }
          return false;
        }
      }
    }
    return false;
  }

  onServiceOptionItemChange(currentServiceIndex: number, event) {
    const currentServiceId = this.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    let currentService = MasterData.getServiceOptionById(currentServiceId);
    if (!currentService) return;
    if (currentService.type == 'delivery') {
      if (this._dropoffNumber == 1) {
        this.setItemValue(`serviceOptions[${currentServiceIndex}].dropOffIndex`, 0);
      }
      this.serviceOptionsChange.emit();
      this.updateCost();
      return;
    } else {
      this.setItemValue(`serviceOptions[${currentServiceIndex}].dropOffIndex`, -1);
      this.updateCost();
    }
    setTimeout(() => this.serviceOptionsChange.emit(), 1);
  }

  onServiceOptionItemDropoffChange(currentServiceIndex: number, event) {
    setTimeout(() => this.serviceOptionsChange.emit(), 1);
    // trường hợp điền Rate trước khi chọn dropoffIndex vì ko update cost
    // nên khi chọn dropoffIndex cần tính lại cost
    let keyRate = `serviceOptions[${currentServiceIndex}].rate`;
    let keyQty = `serviceOptions[${currentServiceIndex}].qty`;
    let keyTotal = `serviceOptions[${currentServiceIndex}].total`;
    let rate = this.getItemValue(keyRate);
    let qty = this.getItemValue(keyQty);
    if (rate && qty) {
      let serviceOptionCost = qty * rate;
      this.setItemValue(keyTotal, serviceOptionCost);
      this.updateCost();
    }
  }

  isDisableServiceOptionDropoffItem(item, currentServiceIndex: number): boolean {
    const currentServiceId = this.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    let arr = this.getItemValue('serviceOptions');
    for (let obj of arr) {
      if (obj._id == currentServiceId && obj.dropOffIndex == item?.value) {
        return true;
      }
    }
    return false;
  }

  onChangeCurrencyType(value) {
    if(value != this.lastCurrencyType) {
      this.lastCurrencyType = value;
      const data = this.getFormData_JSON(true);
      this.createFormInput(data);
      this.setEnableFormGroup(true);
    }

    const fxRateControl = this.formInput?.get("currency")?.get("fxRate");
    if(value === "USD") {
      fxRateControl.clearValidators();
      fxRateControl.updateValueAndValidity();
      fxRateControl.disable();
      this.setItemValue('currency.fxRate', 1)
    }
    else {
      this.setItemValue('currency.fxRate', this.model?.currency?.fxRate || null)
      fxRateControl.enable();
      fxRateControl.setValidators([Validators.required]);
      fxRateControl.markAsDirty();
      fxRateControl.updateValueAndValidity();
    }
  }
}
