<div class="tabs-container">
  <div class="nav nav-tabs">
    <div *ngFor="let tab of tabs; let i = index" class="tab" [ngClass]="{'active': tab.isActive === true }" (click)="selectTab(i)">
      <ng-container *ngIf="checkDoneTab(i)">
        <span class="icon-done" nz-icon nzType="check" nzTheme="outline"></span>
      </ng-container>
      <span>{{ tab.tabTitle }}</span>
    </div>
  </div>
  <div class="tab-content">
    <ng-content></ng-content>
  </div>
</div>