import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AddressUS, AddressUSSmartyMetadata } from '@wearewarp/types/data-model';
import { MasterData } from '@services/master.data';
import { FormGroup } from '@angular/forms';
import { BaseFormItem } from '@app/admin/base/form-item';
import { Subscription } from 'rxjs';
// import { Smarty } from '@app/third-party/smarty';
import { Log } from '@services/log';
import { DateUtil } from '@services/date-utils';
import { NzOptionSelectionChange } from 'ng-zorro-antd/auto-complete';
// import { FormUtil } from '@services/form-util';
import { Const } from '@const/Const';

/**
 * @deprecated no longer used
 */
@Component({
  selector: 'form-address-us',
  templateUrl: './comp.html',
})
export class FormAddressUS extends BaseFormItem {
  
  public static get declaration(): FormGroupDeclaration {return {
    street: {label: 'Street address', required: true, placeHolder: ''},
    street2: {label: 'Street address 2', required: false, placeHolder: ''},
    city: {label: 'City', required: true, placeHolder: ''},
    state: {label: 'State', required: true, placeHolder: ''},
    zipcode: {label: 'Zip code', required: true, placeHolder: ''},
    countryId: {label: 'Country'},
  }}

  public static get metadataDeclaration(): FormGroupDeclaration {return {
    timeZone: {label: 'US short time zone'},
    timeZoneStandard: {label: 'Time zone standard', required: true},
    latitude: {label: 'Latitude', type: 'number', required: true},
    longitude: {label: 'Longitude', type: 'number', required: true}
  }};
  
  protected formGroupDeclaration: FormGroupDeclaration = FormAddressUS.declaration;
  private keys = [];
  get formInputKeys(): Array<string> {
    return this.keys;
  }

  private metadata: AddressUSSmartyMetadata;
  
  protected getApiUrl(): string {
    return null;
  }

  private _isEnabled = true;
  @Input() get isEnabled() {return this._isEnabled}
  set isEnabled(value) {
    this._isEnabled = value;
    this.setEnableFormGroup(value);
  }
  @Input() contentView: TemplateRef<any>;
  @Input() set form(value: FormGroup) {
    this.formInput = value;
    this.keys = Object.keys(this.formInput.controls);
  }
  @Input() zipcode: string = null;
  @Input() showPlaceholderefault = false;
  @Input() showInputStreet2 = false;
  @Input() showInputCountry = false;
  @Input() showInputMetadata = false;
  @Input() freeTypeInput = false;
  @Input() isDisableZipCode = false;
  @Input() isDisableState = false;
  @Input() isDisableCity = false;
  @Output() onAddressAutoComplete = new EventEmitter<Array<SmartySuggestion>>();
  @Output() onVerified = new EventEmitter<AddressUSSmartyMetadata>();
  public modelInputFreeType = '';
  private allStatesUS = MasterData.getStatesUS();
  private allStateNonUS = [];
  private allShortTimezonesUS = DateUtil.listUsTimezones;
  private allShortTimezonesNonUS = [];
  public allTimezones = DateUtil.listTimezones;
  public allCountries = MasterData.getAllCountries();
  private _listAddressAutoComplete: Array<SmartySuggestion> = [];
  public get listAddressAutoComplete() {return this._listAddressAutoComplete}
  public set listAddressAutoComplete(value) {
    this._listAddressAutoComplete = value;
    this.onAddressAutoComplete.emit(value);
  }
  get shouldCreateFormImmediately() {
    return false;
  }

  public isLoading = false;

  get allStates() {
    if (this.isCountryUS) {
      return this.allStatesUS;
    }
    return this.allStateNonUS;
  }

  get allShortTimezones() {
    if (this.isCountryUS) {
      return this.allShortTimezonesUS;
    }
    return this.allShortTimezonesNonUS;
  }

  get isCountryUS(): boolean {
    let countryId = this.formInput?.get('countryId')?.value;
    return !countryId || countryId == Const.CountryId_USA
  }
  
  constructor() {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
    if (this.showInputMetadata) {
      this.formGroupDeclaration['metadata'] = {
        label: 'Metadata', type: 'formGroup', childItem: FormAddressUS.metadataDeclaration
      };
    }
    if (this.isAdminReadOnlyRole){
      this.isEnabled = false;
    }
    if (!this.isEnabled) {
      // Nếu ko enabled thì mới gọi hàm để disable hết đi
      this.setEnableFormGroup(this.isEnabled);
    } else {
      // Giữ nguyên hiện trạng
    }
  }

  @Input() set bindData(model: any) {
    this.metadata = model?.metadata;
    this.model = model;
    if (this.model) {
      if (this.model.countryId && this.model.countryId != Const.CountryId_USA) {
        this.addState(this.model.state);
        if (this.model.timeZone) {
          this.addShortTimezone(this.model.timeZone);
        }
      }
    }
    this.resetInputFreeType();
  }

  private resetInputFreeType() {
    this.modelInputFreeType = this.getAddressStr(this.model);
  }

  private getAddressStr(addr: AddressUS): string {
    if (!addr) return '';
    let str = '';
    for (let key of ['street', 'street2', 'city', 'state', 'zipcode']) {
      if (addr[key]) {
        if (str.length > 0) {
          str += ', ';
        }
        str += addr[key];
      }
    }
    return str;
  }
  
  protected createFormInput(): void {
    // formInput phải được tạo từ component cha rồi truyền vào
  }

  private subAddressVerification: Subscription;
  // private subAddressSuggestion: Subscription;
  private timerAddressSuggestion;

  private tryAddressSuggestion(elm: HTMLInputElement) {
    // clearTimeout(this.timerAddressSuggestion);
    // let street = elm.value;
    // if (!street) {
    //   this.subAddressVerification?.unsubscribe();
    //   this.subAddressSuggestion?.unsubscribe();
    //   this.listAddressAutoComplete = [];
    //   return;
    // }
    // this.timerAddressSuggestion = setTimeout(() => this.doAddressSuggestion(elm, street), 100);
  }

  private doAddressSuggestion(elm: HTMLInputElement, street: string, selected: string = null) {
    // this.subAddressVerification?.unsubscribe();
    // this.subAddressSuggestion?.unsubscribe();
    // this.isLoading = true;
    // // let inputAddressUs: AddressUS = {street};
    // let inputAddressUs: AddressUS = {street, state: '', city: '', zipcode: ''};
    // if (this.zipcode) {
    //   inputAddressUs.zipcode = this.zipcode;
    //   // inputAddressUs = {street, zipcode: this.zipcode };
    // }
    // this.subAddressSuggestion = Smarty.autocompleteUS(inputAddressUs, selected).subscribe(
    //   resp => {
    //     if (selected) {
    //       elm.blur();
    //     }
    //     this.listAddressAutoComplete = resp.result.map(it => {
    //       it.desc = Smarty.buildAddressUS(it);
    //       return it;
    //     });
    //     if (selected) {
    //       setTimeout(() => elm.focus(), 300)
    //     }
    //     this.isLoading = false;
    //   }, err => {
    //     Log.e('doAddressSuggestion failed. ', err);
    //     this.isLoading = false;
    //   }
    // );
  }

  public verifyAddress() {
    // let addr: AddressUS = {
    //   street: this.formInput.get('street').value,
    //   street2: this.formInput.get('street2').value,
    //   city: this.formInput.get('city').value,
    //   state: this.formInput.get('state').value,
    //   zipcode: this.formInput.get('zipcode').value,
    // };
    // this.metadata = null;
    // this.subAddressVerification?.unsubscribe();
    // this.isLoading = true;
    // this.subAddressVerification = Smarty.streetUS(addr).subscribe(
    //   resp => {
    //     Log.d('verifyAddress resp: ', resp);
    //     let metadata = resp?.lookups[0]?.result[0]?.metadata;
    //     if (metadata) {
    //       this.metadata = {};
    //       for (let key of ['timeZone', 'utcOffset', 'countyName', 'rdi', 'latitude', 'longitude', 'obeysDst']) {
    //         this.metadata[key] = metadata[key];
    //       }
    //     }
    //     if (this.metadata && this.metadata.timeZone) {
    //       this.metadata.timeZoneStandard = DateUtil.mapTimezoneUS(this.metadata.timeZone);
    //     }
    //     let formMetadata = this.formInput.get('metadata')
    //     if (formMetadata) {
    //       FormUtil.bindData(formMetadata, this.metadata, this.formGroupDeclaration.metadata.childItem);
    //     }
    //     this.onVerified.emit(this.metadata);
    //     this.isLoading = false;
    //   }, err => {
    //     Log.e('verifyAddress failed. ', err);
    //     this.showErr('Cannot get time zone due to smarty\'s api failed.')
    //     this.isLoading = false;
    //   }
    // );
  }

  onSelectSuggestedAddress(event: NzOptionSelectionChange, elm: HTMLInputElement, index: number) {
    if (event.isUserInput) {
      setTimeout(() => this._onSelectSuggestedAddress(elm, index), 1);
    }
  }

  private _onSelectSuggestedAddress(elm: HTMLInputElement, index: number) {
    // // clearTimeout(this.timerResetInputFreeType);
    // let addr = this.listAddressAutoComplete[index];
    // if (addr.entries > 1) {
    //   this.doAddressSuggestion(elm, addr.streetLine, Smarty.buildSelectedParamUS(addr));
    // } else {
    //   if (!this.isDisableZipCode || this.formInput.get('zipcode')?.value?.toString() == addr?.zipcode?.toString()) {
    //     this.formInput.get('street').setValue(`${addr.streetLine} ${addr.secondary}`);
    //   } else {
    //     this.formInput.get('street').setValue('');
    //   }
    //   this.formInput.get('street2').setValue(null);
    //   if (!this.isDisableCity) {
    //     this.formInput.get('city').setValue(addr.city);
    //   }
    //   if (!this.isDisableState) {
    //     this.formInput.get('state').setValue(addr.state);
    //   }
    //   if (!this.isDisableZipCode) {
    //     this.formInput.get('zipcode').setValue(addr.zipcode);
    //   }
    //   this.verifyAddress();
    //   if (this.freeTypeInput) {
    //     let addrStr = Smarty.buildAddressUS(addr);
    //     elm.value = addrStr;
    //   }
    // }
  }

  addState(value: string) {
    let code = value?.trim();
    if (!code) {
      return;
    }
    for (let item of this.allStateNonUS) {
      if (item.code == code) {
        return;
      }
    }
    this.allStateNonUS.push({code: code});
  }

  addShortTimezone(value: string) {
    let code = value?.trim();
    if (!code) {
      return;
    }
    for (let item of this.allShortTimezonesNonUS) {
      if (item == code) {
        return;
      }
    }
    this.allShortTimezonesNonUS.push(code);
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'countryId':
        this.setItemValue('state', null);
        this.allStateNonUS = [];
        this.allShortTimezonesNonUS = [];
        return;
      case 'freeTypeInput':
      case 'street':
        return this.tryAddressSuggestion(event.target);
      case 'zipcode':
        return; // TODO
    }
  }
  
  onInputKeyPress(event, key) {
    if (key == 'zipcode') {
      return this.onlyNumberKey(event)
    }
    return true;
  }

  onInputFocusOut(event: any, key: any): void {
    switch (key) {
      case 'latitude':
      case 'longitude':
        let lat = this.getItemValue('metadata.latitude');
        let lng = this.getItemValue('metadata.longitude');
        if (lat != null && lng != null) {
          this.getTimezoneByLocation(lat, lng);
        }
        break;
      case 'street':
      case 'street2':
      case 'city':
      case 'state':
      case 'zipcode':
        if (this.needUpdate) {
          this.verifyAddress();
        }
        break;
      // case 'freeTypeInput':
      //   // Nếu user click ra ngoài, mà không chọn địa chỉ từ kết quả suggestion,
      //   // hoặc là api smarty lỗi, ko trả về kết quả gì thì reset input 
      //   this.timerResetInputFreeType = setTimeout(() => this.resetInputFreeType(), 200);
      //   break;
      default:
        break;
    }
  }

  private getTimezoneByLocation(lat, lng) {
    let url = `${Const.API_PUBLIC('timezone_by_location')}?lat=${lat}&lng=${lng}`;
    this.api.GET(url).subscribe(
      resp => {
        Log.d(`Location [${lat}, ${lng}], timezone: `, resp);
        let data = resp.data;
        let timeZoneStandard = data.zoneName;
        let timeZone = data.abbreviation;
        let currentValueTimeZoneStandard = this.getItemValue('metadata.timeZoneStandard');
        if (!currentValueTimeZoneStandard) {
          // Nếu form chưa có dữ liệu thì điền luôn
          this.applyTimezone(timeZoneStandard, timeZone);
        } else if (currentValueTimeZoneStandard != timeZoneStandard) {
          // Nếu dữ liệu trên form khác với API trả về thì confirm
          this.confirmYesNo(`The time zone you've entered <b>${currentValueTimeZoneStandard}</b> is different to the time zone responsed by API <b>${timeZoneStandard}</b>.<br><br>Do you want to replace with the value from API <b>${timeZoneStandard}</b>?`, () => {
            this.applyTimezone(timeZoneStandard, timeZone);
          });
        }
      },
      err => {
        this.showErr(err);
      }
    )
  }

  private applyTimezone(timeZoneStandard, timeZone) {
    this.addShortTimezone(timeZone);
    this.setItemValue('metadata.timeZoneStandard', timeZoneStandard);
    this.setItemValue('metadata.timeZone', timeZone);
  }

  private _getPlaceHolder(key: string, defaultValue: string): FormControlPlaceHolder {
    let str = super.getPlaceHolder(key);
    if (!str) {
      str = defaultValue;
    }
    return str;
  }

  getPlaceHolder(key: string): FormControlPlaceHolder {
    switch (key) {
      case 'street': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Number & street' : '');
      case 'street2': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Street 2' : '');
      case 'city': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'City' : '');
      case 'state': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'State' : '');
      case 'zipcode': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Zip code' : '');
      case 'countryId': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Country (default is USA)' : '');
      case 'timeZone': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Short time zone US' : '');
      case 'timeZoneStandard': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Standard time zone' : '');
      case 'latitude': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Latitude' : '');
      case 'longitude': return this._getPlaceHolder(key, this.showPlaceholderefault ? 'Longitude' : '');
      default: return this._getPlaceHolder(key, this.showPlaceholderefault ? '' : '');
    }
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(true); // kể cả nếu edit thì cũng lấy hết form data giống như tạo mới
    if (this.metadata) {
      data.metadata = this.metadata;
    }
    return data;
  }

}