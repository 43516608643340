<div>
  <button nz-button [disabled]="!visible" nzType="default" (click)="inputFile.click()">
    <i *ngIf="!!nzIconSelectFile" nz-icon [nzType]="nzIconSelectFile" nzTheme="outline"></i>
    {{textSelectFile}}
  </button>
  <input #inputFile type="file" hidden accept=".png,.jpg,.jpeg,.pdf" (change)="onFileSelected(inputFile)">
  <div class="list-images-preview top10">
    <div *ngFor="let file of selectedFiles; let i = index" style="display: flex">
      <div class="file-name">
        <a>{{ file.fileName }}</a>
      </div>
      <button *ngIf="visible" class="del-image" nz-button nzType="primary" nzShape="circle" nzDanger (click)="onBtnDelImage(i)"><i nz-icon nzType="delete"></i></button>
    </div>
  </div>
</div>