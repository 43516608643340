
import { Component, Input, SimpleChanges } from '@angular/core';
import { Pie } from '@antv/g2plot';
import { Utils } from './../../../../services/utils';
import { ChartBase } from '../chart-base';

@Component({
  selector: '[chart-pie]',
  templateUrl: './index.html',
})
export class ChartPieComponent extends ChartBase {
  
  public data;
  public color;
  private _options: any;
  @Input() set options(value) {
    this._options = value;
    this.data = value?.data;
    if (Utils.isArrayNotEmpty(this.data)) {
      this.color = this.data.map(item => item.color);
    }
  }
  get options() {
    return this._options;
  }
  @Input() id: string;

  ngOnInit(): void {
    setTimeout(() => {
      this.chart = new Pie(this.id, {
        appendPadding: 10,
        data: this.data,
        angleField: 'value',
        colorField: 'type',
        color: this.color,
        radius: 0.9,
        label: {
          type: 'inner',
          offset: '-30%',
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: 'center',
          },
        },
        legend: {
          position: 'top',
        },
        interactions: [{ type: 'element-active' }],
      });
      this.chart.render();
    }, 500);
  }

  ngOnChanges(): void {
    if(this.data && this.chart) {
      setTimeout(() => {
        this.chart.changeData(this.data);
      }, 500);
    }
  }
  
}