import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Utils } from "@services/utils";

@Component({
  selector: "[ship-rec]",
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})
export class ShipRec extends BaseComponent {

  isOpenShipRec = true;
  activeNumber:Number = null;
  private _shipRecLocationHistories = [];
  @Input() mapBox = null;
  @Input() orders = [];
  @Input() get shipRecLocationHistories() { return this._shipRecLocationHistories };
  set shipRecLocationHistories(value) {
    this._shipRecLocationHistories = [];
    this.isOpenShipRec = true;
    if (!value || !Utils.isArrayNotEmpty(value)) {
      return;
    }
    value.forEach((shipRecInfo, index) => {
      if (shipRecInfo?.location) {
        let rectInfoHtml = `<div class="f11">
                            <div><b>Battery:</b> ${shipRecInfo?.battery || 'N/A'}</div>
                            <div><b>Temperature:</b> ${shipRecInfo?.temp || 'N/A'}</div>
                            <div>${this.getTimeShipRec(new Date(shipRecInfo.location.timestamp * 1000))}</div>
                          </div>
                          `;
        this._shipRecLocationHistories.push({
          coordinates: [shipRecInfo.location.long, shipRecInfo.location.lat],
          description: rectInfoHtml,
          isShowMapPopupDefault: index == 0 ? true : false,
          when: new Date(shipRecInfo.location.timestamp * 1000),
          orderId: this.orders?.length > 1 ? shipRecInfo?.orderId : null
        });
      }
    });
    if (this._shipRecLocationHistories?.length) {
      this.mapBox.addPopupPoint(this._shipRecLocationHistories);
    }
  }
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onBtnShipRecClose(value = false) {
    this.isOpenShipRec = value;
  }

  getTimeShipRec(time) {
    if (!time) {
      return '';
    }
    return this.displayDateTime(time, 'dddd, MMMM D, YYYY h:mm A');
  }

  onClickToMap(historyLocation, i) {
    this.activeNumber = i;
    this.mapBox.showMapNewPopup(historyLocation.coordinates, historyLocation.description);
  }
  

}