<ng-container *ngIf="shipRecLocationHistories?.length">
  <div #historyBox id="history-box">
    <ng-container *ngIf="isOpenShipRec" >
      <div class="panel">
        <div class="header-panel">
          <div class="f18 bold">Location History</div>
          <i class="ic-close clickable" (click)="onBtnShipRecClose(false)" nz-icon nzType="close" nzTheme="outline"></i>
        </div>
        <div class="body-panel">
          <div>
            <ng-container *ngFor="let history of shipRecLocationHistories; let i = index">
              <div class="mt-20">
                <div class="title" [ngClass]="{active: activeNumber == i}" (click)="onClickToMap(history, i)">Location {{shipRecLocationHistories.length - i}}
                  <ng-container *ngIf="history.orderId" >
                    <span>(WarpId: {{history.orderId}})</span>
                  </ng-container>
                </div>
                <div class="description">{{getTimeShipRec(history.when)}}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      
    </ng-container>
    <ng-container *ngIf="!isOpenShipRec">
      <img src="/assets/img/ship_rec.png" style="width:70px; cursor: pointer; padding: 3px; background: white;" (click)="onBtnShipRecClose(true)" />
    </ng-container>
  </div>
</ng-container>